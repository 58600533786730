import templateUrl from './dashboardNav.tpl.html';
import readOnlyTooltipTemplateUrl from '../../common/tooltips/readOnlyTooltip.html';
import noWritePermissionsTooltipTemplateUrl from '../dashboardGroup/noWritePermissionsTooltip.tpl.html';
import addDashboardToGroupTemplateUrl from '../dashboardGroup/addDashboardToGroup.tpl.html';
import removeDashboardFromGroupTemplateUrl from '../dashboardGroup/removeDashboardFromGroup.tpl.html';
import { PERMISSIONS_ACTIONS } from '../../common/data/util/permissionsChecker';
import AccessControlObjectType from '../../../common/ui/accessControl/AccessControlObjectType';
import { ngRoute } from '../../../app/routing/ngRoute';
import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';
import { NotificationCategory, NotificationService } from '@splunk/olly-common';

angular
    .module('signalview.dashboard')

    .directive('dashboardNav', [
        '$q',
        'sfxModal',
        '$rootScope',
        '_',
        'pageService',
        'pageTypeService',
        'pageDisplayTitle',
        'dashboardUtil',
        '$location',
        '$log',
        '$window',
        'userAnalytics',
        'dashboardCloner',
        'aclDashboardCloner',
        'dashboardV2Util',
        'CHART_DISPLAY_EVENTS',
        'CROSS_LINK_EVENTS',
        'crossLinkUtils',
        'sidebarService',
        'config',
        'urlOverridesService',
        'zeroStateService',
        'globalNavUpdateService',
        'chartUtils',
        'sharingService',
        'currentUser',
        'confirmService',
        'dateService',
        'moment',
        'newDashboardService',
        'timepickerUtils',
        'dashboardGroupService',
        'dashboardGroupUtil',
        'dashboardV2Service',
        'homepageV2Service',
        'dashboardGroupCache',
        'appNotificationService',
        'clipboard',
        'featureEnabled',
        'fullscreen',
        'dashboardGroupSettingsModal',
        'dashboardSettingsModal',
        'dashboardVariablesService',
        'crossLinkDataService',
        'userV2Service',
        'download',
        'SYSTEM_USER_ID',
        'title',
        'dashboardMirrorService',
        'dashboardVariableUtils',
        'permissionsChecker',
        'METABASE_CONSISTENCY_PRAYER_DURATION_MS',
        'hasCapability',
        function (
            $q,
            sfxModal,
            $rootScope,
            _,
            pageService,
            pageTypeService,
            pageDisplayTitle,
            dashboardUtil,
            $location,
            $log,
            $window,
            userAnalytics,
            dashboardCloner,
            aclDashboardCloner,
            dashboardV2Util,
            CHART_DISPLAY_EVENTS,
            CROSS_LINK_EVENTS,
            crossLinkUtils,
            sidebarService,
            config,
            urlOverridesService,
            zeroStateService,
            globalNavUpdateService,
            chartUtils,
            sharingService,
            currentUser,
            confirmService,
            dateService,
            moment,
            newDashboardService,
            timepickerUtils,
            dashboardGroupService,
            dashboardGroupUtil,
            dashboardV2Service,
            homepageV2Service,
            dashboardGroupCache,
            appNotificationService,
            clipboard,
            featureEnabled,
            fullscreen,
            dashboardGroupSettingsModal,
            dashboardSettingsModal,
            dashboardVariablesService,
            crossLinkDataService,
            userV2Service,
            download,
            SYSTEM_USER_ID,
            title,
            dashboardMirrorService,
            dashboardVariableUtils,
            permissionsChecker,
            METABASE_CONSISTENCY_PRAYER_DURATION_MS,
            hasCapability
        ) {
            return {
                restrict: 'E',
                scope: {
                    data: '=',
                    orgOverviewContext: '=?',
                },
                templateUrl,
                controller: [
                    '$scope',
                    '$element',
                    '$timeout',
                    '$filter',
                    function ($scope, $element, $timeout, $filter) {
                        Object.defineProperty($scope, 'isSnapshot', {
                            get: () => !!$scope.snapshot?.id,
                        });

                        $scope.retainedDashboardState = {
                            //initialize this even though we'll pull from dashboard, because it needs initial state for global nav
                            filterState: {
                                timePickerDirty: false,
                                sourceFilterDirty: false,
                                variablesDirty: false,
                                pointDensityDirty: false,
                                eventOverlayDirty: false,
                            },
                        };
                        globalNavUpdateService.update({
                            dashboardState: $scope.retainedDashboardState,
                        });
                        $scope.eventSidebarParams = {
                            showOverlaidEventsInPanel: false,
                        };
                        $scope.chartClips = clipboard.getClips();

                        $scope.readOnlyEnabled = featureEnabled('readOnly');
                        $scope.dashboardViewsEnabled = featureEnabled('dashboardViews');
                        $scope.allowBuiltinMirrors = featureEnabled('allowBuiltinMirrors');
                        $scope.ollyFilterbarEnabled = featureEnabled('ollyFilterbar');
                        $scope.customDashboardAsHomePageEnabled = featureEnabled(
                            'enableCustomDashboardHomePage'
                        );
                        $scope.isHomepage = ngRoute.route.url.startsWith('/home');
                        $scope.deleteModalState = {
                            isOpen: false,
                            description: '',
                            title: '',
                            typeConfirmation: false,
                            callToAction: '',
                            listTitle: '',
                            listElements: [],
                            onCancel: function () {
                                $timeout(closeAndClearModal, 0);
                            },
                        };

                        let dashboardViewBannerShown = false;
                        $scope.isMirror = false;

                        function openMetricsEventsSidebar() {
                            if (!$scope.orgOverviewContext) {
                                $scope.isMetricsEventsSidebarOpen = true;
                            }
                        }

                        $scope.openMetricsSidebar = function () {
                            if ($scope.isMetricsEventsSidebarOpen) {
                                $scope.$broadcast('selectMetricsTab');
                            } else {
                                $scope.eventsTabSelected = false;
                                openMetricsEventsSidebar();
                            }
                        };

                        $scope.openEventsSidebar = function () {
                            if ($scope.isMetricsEventsSidebarOpen) {
                                $scope.$broadcast('selectEventsTab');
                            } else {
                                $scope.eventsTabSelected = true;
                                openMetricsEventsSidebar();
                            }
                        };

                        $scope.closeMetricsSidebar = function () {
                            $scope.isMetricsEventsSidebarOpen = false;
                        };

                        $scope.currentCharts = {};
                        $scope.retainedDashboardState.pastingInProgress = false;
                        $scope.retainedDashboardState.copyInProgress = 0;
                        $scope.retainedDashboardState.isInDashboardPage = true;

                        $scope.onPillEnter = function (variableDef, values) {
                            if (variableDef && variableDef.replaceOnly) {
                                $scope.$broadcast('variableHovered', variableDef.property, values);
                            } else {
                                $scope.$broadcast('variableHovered', null);
                            }
                            // trigger the digest cycle on hovers over the dashboard variable
                            $scope.$applyAsync();
                        };

                        const hasSuperpowers = config('superpower.unreleasedFeatures');

                        $scope.hasCreateDashboardGroupCapability = false;
                        hasCapability(Capability.CREATE_DASHBOARD_GROUP).then(
                            (hasCreateDashboardGroupCapability) =>
                                ($scope.hasCreateDashboardGroupCapability =
                                    hasCreateDashboardGroupCapability)
                        );

                        $scope.hasDeleteDashboardGroupCapability = false;
                        hasCapability(Capability.DELETE_DASHBOARD_GROUP).then(
                            (hasDeleteDashboardGroupCapability) =>
                                ($scope.hasDeleteDashboardGroupCapability =
                                    hasDeleteDashboardGroupCapability)
                        );

                        $scope.hasUpdateDashboardCapability = false;
                        hasCapability(Capability.UPDATE_DASHBOARD).then(
                            (hasUpdateDashboardCapability) =>
                                ($scope.hasUpdateDashboardCapability = hasUpdateDashboardCapability)
                        );

                        hasCapability(Capability.CREATE_SHAREABLE_SNAPSHOT).then(
                            (hasCreateShareableSnapshotCapability) =>
                                ($scope.hasCreateShareableSnapshotCapability =
                                    hasCreateShareableSnapshotCapability)
                        );

                        Object.defineProperty($scope, 'isServiceDiscovery', {
                            get: () => $scope.model?.creator === SYSTEM_USER_ID,
                        });
                        Object.defineProperty($scope, 'groupIsServiceDiscovery', {
                            get: () =>
                                ($scope.parentPage?.creator || $scope.parentPage?.sf_creator) ===
                                SYSTEM_USER_ID,
                        });

                        // private field for watcher
                        Object.defineProperty($scope, '_isReadOnly', {
                            get: () =>
                                ($scope.readOnlyEnabled && $scope.model?.locked) ||
                                ($scope.isServiceDiscovery && !hasSuperpowers),
                        });
                        $scope.$watch('_isReadOnly', () => {
                            $scope.retainedDashboardState.readOnly = $scope._isReadOnly;
                        });

                        Object.defineProperty($scope, 'isEditable', {
                            get: () =>
                                !$scope._isReadOnly &&
                                $scope.hasWritePermission &&
                                $scope.hasUpdateDashboardCapability,
                        });

                        const permissionsDeferred = $q.defer();
                        $scope.permissionsPromise = permissionsDeferred.promise;

                        $scope.refreshAppearanceCount = function (checkForSelf) {
                            if (!$scope.model) return;

                            // This is a list of all the dashboard config objects for this
                            // dashboard across all groups, not just this group.
                            $scope.allDashboardConfigs = dashboardUtil
                                .getAllDashboardConfigs($scope.model.id)
                                .then((configs) => {
                                    if (checkForSelf) {
                                        const currentConfig = configs.find((config) => {
                                            return (
                                                config.dashboardId === $scope.model.id &&
                                                config.configId === $scope.configId
                                            );
                                        });

                                        if (!currentConfig) {
                                            configs.push(
                                                angular.copy($scope.activeDashboardConfig)
                                            );
                                        }
                                    }

                                    return configs;
                                });

                            $scope.allDashboardConfigs.then((configs) => {
                                $scope.appearanceCount = configs.length;
                                $scope.groupCount = _.uniq(
                                    configs.filter(Boolean).map((config) => config.groupId)
                                ).length;
                            });

                            return $scope.allDashboardConfigs;
                        };

                        function isSourceFilterOrVariableInDirtyState() {
                            return (
                                $scope.retainedDashboardState.filterState.sourceFilterDirty ||
                                $scope.retainedDashboardState.filterState.variablesDirty
                            );
                        }

                        function isTimeOrDensityOrSelectedEventOverlaysInDirtyState() {
                            return (
                                $scope.retainedDashboardState.filterState.pointDensityDirty ||
                                $scope.retainedDashboardState.filterState.timePickerDirty ||
                                $scope.retainedDashboardState.filterState.eventOverlayDirty
                            );
                        }

                        function isSaveEnabledForGroup() {
                            return (
                                $scope.hasGroupWritePermission &&
                                !$scope.groupIsServiceDiscovery &&
                                isSourceFilterOrVariableInDirtyState()
                            );
                        }

                        function isSaveEnabledForDashboard() {
                            return (
                                $scope.isEditable &&
                                isTimeOrDensityOrSelectedEventOverlaysInDirtyState()
                            );
                        }

                        $scope.getSaveButtonTooltipTemplate = function () {
                            if ($scope.readOnlyEnabled && $scope.model && $scope.model.locked) {
                                return readOnlyTooltipTemplateUrl;
                            } else if (!$scope.saveEnabled()) {
                                return noWritePermissionsTooltipTemplateUrl;
                            } else {
                                return '';
                            }
                        };

                        $scope.saveEnabled = function () {
                            let saveEnabled = true;

                            const hasGroupWritePermission =
                                $scope.hasGroupWritePermission && !$scope.groupIsServiceDiscovery;
                            // mirrors has inherit from current dashboard group permission type
                            const isCustomizableMirror =
                                $scope.isMirror && !$scope._isReadOnly && hasGroupWritePermission;
                            const isEditable = $scope.isEditable || isCustomizableMirror;

                            // can't save at all if dashboard is readOnly or you have no permissions at any level
                            if (!isEditable && !hasGroupWritePermission) {
                                saveEnabled = false;
                            } else if (
                                !featureEnabled('dashboardViews') ||
                                $scope.getAppearanceCount() === 1
                            ) {
                                // if mirror does not exist and we have no dashboard permissions, save is disabled
                                if (
                                    !isEditable &&
                                    (isSourceFilterOrVariableInDirtyState() ||
                                        isTimeOrDensityOrSelectedEventOverlaysInDirtyState())
                                ) {
                                    saveEnabled = false;
                                }
                            } else {
                                // if we have mirrors and no dashboard permission, we cannot change time, density,
                                // or event overlays - save should still be enabled if we have group permissions
                                // and there are group level changes
                                if (
                                    !isEditable &&
                                    isTimeOrDensityOrSelectedEventOverlaysInDirtyState() &&
                                    !isSaveEnabledForGroup()
                                ) {
                                    saveEnabled = false;
                                }

                                // if we have mirrors and no group permission, we cannot change source filters or
                                // variables - save should still be enabled if we have dashboard permissions
                                // and there are dashboard level changes
                                if (
                                    !hasGroupWritePermission &&
                                    isSourceFilterOrVariableInDirtyState() &&
                                    !isSaveEnabledForDashboard()
                                ) {
                                    saveEnabled = false;
                                }
                            }

                            return saveEnabled;
                        };

                        // Wrapper function to pass the number of times the current dashboard appears to the dashboard directive
                        $scope.getAppearanceCount = () => $scope.appearanceCount;

                        function ensureUrlHasDashboardViewsParams(dashboardAndGroupData) {
                            const dashboardViewsParams = extractIds(dashboardAndGroupData);

                            // We want to avoid setting the groupId param without a valid configId
                            // It is preferable to have a url with only the dashboardId or both groupId and configId
                            if (!dashboardViewsParams.configId) {
                                return;
                            }

                            if (urlNeedsDashboardViewsParams(dashboardViewsParams)) {
                                dashboardUtil.addDashboardSearchParams(dashboardViewsParams, true);
                            }
                        }

                        function extractIds(dashboardAndGroupData) {
                            return {
                                dashboardId: dashboardAndGroupData.dashboard.id,
                                groupId: dashboardAndGroupData.group?.id,
                                configId: dashboardAndGroupData.configId,
                            };
                        }

                        function urlNeedsDashboardViewsParams(dashboardViewsParams) {
                            const shouldHaveParams =
                                $scope.dashboardViewsEnabled &&
                                dashboardViewsParams.groupId &&
                                dashboardViewsParams.configId;

                            const isMissingParams = !(
                                ngRoute.params.groupId && ngRoute.params.configId
                            );

                            return shouldHaveParams && isMissingParams;
                        }

                        function onData(data) {
                            if (!data.snapshot && data.dashboard) {
                                ensureUrlHasDashboardViewsParams(data);

                                // Store the groupId and configId params for views edge cases
                                $scope.urlGroupId = ngRoute.params.groupId;
                                $scope.urlConfigId = ngRoute.params.configId;
                            }

                            const dashboard = data.dashboard;
                            const group = data.group;

                            globalNavUpdateService.update({
                                object: data.dashboard,
                                snapshot: data.snapshot,
                                dashboardGroup: group,
                            });

                            $scope.model = dashboard;
                            $scope.configId = data.configId || null;
                            $scope.asyncData = data;
                            $scope.parentPage = group;

                            // The group may be empty, therefore we may not assume there is a model
                            $scope.retainedDashboardState.dashboardID = $scope.model
                                ? $scope.model.id
                                : null;

                            if (
                                $scope.parentPage &&
                                $scope.parentPage.dashboardConfigs &&
                                $scope.parentPage.dashboardConfigs.length
                            ) {
                                $scope.activeDashboardConfig = dashboardUtil.getConfig(
                                    $scope.parentPage.dashboardConfigs,
                                    $scope.configId
                                );
                            } else {
                                // TODO(trevor): This should only be necessary until all dashboards and groups conform to the new data model
                                $scope.activeDashboardConfig = {
                                    dashboardId: $scope.model ? $scope.model.id : null,
                                    filtersOverride: {},
                                };
                            }

                            $scope.refreshAppearanceCount(true);

                            $scope.getMirrorsDisableReasonTooltipText = function () {
                                if ($scope.isWorkspace) {
                                    return 'You cannot add a mirror before the dashboard has been saved';
                                }

                                if (!$scope.canBeMirrored) {
                                    return "Convert this dashboard’s permissions to 'Inherit from dashboard group' to add a mirror";
                                }

                                return null;
                            };

                            $scope.isMirrorOfServiceDiscovery = function () {
                                return $scope.isServiceDiscovery && $scope.isMirror;
                            };

                            dashboardMirrorService
                                .isDashboardMirror($scope.model?.id)
                                .then((isMirror) => ($scope.isMirror = isMirror));

                            $scope.isEditableServiceDiscoveryMirror = function () {
                                return (
                                    $scope.isMirrorOfServiceDiscovery &&
                                    !$scope.groupIsServiceDiscovery
                                );
                            };

                            $scope.snapshot = data.snapshot || {};

                            // time cannot be overridden by mirrors, so always pull from the base dashboard model.
                            // initrange is used by the timepicker to track "default"
                            if ($scope.model && $scope.model.filters && $scope.model.filters.time) {
                                $scope.initRange =
                                    timepickerUtils.getChartConfigParametersFromURLTimeObject(
                                        $scope.model.filters.time
                                    );
                            } else {
                                $scope.initRange = {};
                            }

                            updateChartFiltersLink();
                            postHierarchyInitialize();

                            // this does not involve angular, so no need to digest.
                            $window.setTimeout(() => {
                                // floating toolbar
                                // during transition, there could be more than one element matches.
                                const toolbar = angular
                                    .element('.sf-secondary-toolbar-container', $element)
                                    .last();
                                const dashboard = angular.element('dashboard', $element).last();
                                const scrollParent = toolbar.scrollParent();
                                let lastScrollTop = 0;
                                let goingFloat;

                                scrollParent.scroll(function () {
                                    const height = toolbar.innerHeight();
                                    const floating = toolbar.hasClass('floating');
                                    const scrollTop = scrollParent.scrollTop();
                                    if (scrollTop === 0) {
                                        toolbar.css({ top: 0 });
                                        toolbar.removeClass('floating');
                                        dashboard.css({ 'margin-top': 0 });
                                    } else if (scrollTop > height) {
                                        if (floating) {
                                            if (scrollTop < lastScrollTop) {
                                                // scroll up
                                                toolbar.css({ top: 0 });
                                            } else if (scrollTop > lastScrollTop) {
                                                // scroll down
                                                toolbar.css({ top: -height + 'px' });
                                            }
                                        } else {
                                            toolbar.css({ top: -height + 'px' });
                                            if (!goingFloat) {
                                                goingFloat = $timeout(function () {
                                                    dashboard.css({ 'margin-top': height + 'px' });
                                                    toolbar.addClass('floating');
                                                    goingFloat = null;
                                                }, 250);
                                            }
                                        }
                                    }
                                    lastScrollTop = scrollTop;
                                });
                            }, 0);

                            return updateWritePermissionState(dashboard, group);
                        }

                        $scope.data.then((data) => $scope.$applyAsync(() => onData(data)));

                        // Only update page title to current dashboard name if we are viewing dashboard as a page itself
                        // and not otherwise (like when on organization overview page).
                        if (!$scope.orgOverviewContext && !$scope.isHomepage) {
                            $scope.$watch('model.name', function () {
                                if ($scope.model) {
                                    const dashboardTitle = $scope.model.name || 'Unsaved Dashboard';
                                    title.updateTitle(`Dashboard - ${dashboardTitle}`);
                                }
                            });
                        }

                        $scope.$on(
                            CROSS_LINK_EVENTS.OPEN_CONFIG_MODAL,
                            ($event, crossLinkParams) => {
                                if (showActionMenu()) {
                                    showDashboardCrossLinks(crossLinkParams);
                                } else {
                                    // if the current context cannot be edited, then send to the Global Data Link instead
                                    crossLinkUtils
                                        .getGlobalDataLink(crossLinkParams.propValPair)
                                        .then((url) => $location.url(url));
                                }
                            }
                        );

                        // the linking function here can take a while due to angular-gridster's transclusions, but
                        // we still want to show the shroud for the duration of it.  this means the default visibility state
                        // of the shroud must be true, such that we can turn it off after completion of the linking and transclude
                        $timeout(function () {
                            $scope.linkFnInProgress = true;
                        }, 0);

                        $scope.linkFnInProgress = false;

                        $scope.sidebarSvc = sidebarService;
                        $scope.isMetricsEventsSidebarOpen = false;

                        // Make dashboard re-render after sidebar is shown or hidden
                        $scope.$watch('isMetricsEventsSidebarOpen', function (nv, ov) {
                            if (nv === ov) {
                                return;
                            }
                            $timeout(function () {
                                $rootScope.$broadcast(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE);
                            }, 0);
                        });

                        const deleteDisabled = function () {
                            return (
                                !$scope.model ||
                                ($scope.readOnlyEnabled && $scope.model.locked) ||
                                $scope.isServiceDiscovery ||
                                !$scope.hasWritePermission ||
                                $scope.isSnapshot ||
                                !$scope.canDelete
                            );
                        };

                        $scope.deleteDisabled = deleteDisabled;

                        const closeAndClearModal = function () {
                            $scope.deleteModalState.isOpen = false;
                            $scope.deleteModalState.typeConfirmation = false;
                            $scope.deleteModalState.callToAction = undefined;
                            $scope.deleteModalState.listTitle = '';
                            $scope.deleteModalState.listElements = [];
                        };

                        function handleDashboardDelete() {
                            closeAndClearModal();

                            const modalInstance = sfxModal.open({
                                template: '<div><i class="busy-spinner-light"></i></div>',
                                windowClass: 'full-screen-busy-spinner',
                                backdrop: 'static',
                                keyboard: false,
                            });

                            const deletedId = $scope.model.id;
                            const pageId = $scope.parentPage?.sf_id || $scope.parentPage?.id;

                            dashboardV2Util
                                .deleteDashboard({
                                    dashboardId: deletedId,
                                    groupId: $scope.model.groupId,
                                    configId: $scope.model.configId,
                                })
                                .then(
                                    () => {
                                        userAnalytics.event('dashboard', 'delete');

                                        if (!pageId) {
                                            $location.path('/home');
                                            return;
                                        }

                                        dashboardGroupCache.removeDashboardFromTabCache(
                                            pageId,
                                            deletedId
                                        );

                                        // this clears configId and groupId, which should be reset when entering the route by controller
                                        $location.url(`/page/${pageId}`);
                                    },
                                    (e) => {
                                        $log.error('There was an error removing the dashboard ', e);
                                        confirmService.confirm({
                                            title: 'Dashboard Deletion Error',
                                            text: ['There was an error deleting the dashboard.'],
                                            yesText: 'Ok',
                                            noText: '',
                                        });
                                    }
                                )
                                .finally(() => {
                                    modalInstance.close();
                                });
                        }

                        function postHierarchyInitialize() {
                            setPageName();
                            if (!$scope.isSnapshot) {
                                $scope.deleteDashboard = function () {
                                    $scope.deleteModalState.onDelete = function () {
                                        $timeout(handleDashboardDelete, 0);
                                    };

                                    const dashboardName =
                                        $scope.activeDashboardConfig.nameOverride ||
                                        $scope.model.name;

                                    $scope.deleteModalState.title = 'Delete dashboard';
                                    $scope.deleteModalState.description =
                                        'You are about to permanently delete the dashboard ' +
                                        dashboardName +
                                        ', as well as all its charts.';
                                    $scope.deleteModalState.typeConfirmation = false;
                                    $scope.deleteModalState.isOpen = true;
                                    $scope.deleteModalState.callToAction = 'Delete';
                                };

                                $scope.removeDashboardFromGroup = function () {
                                    const parentPage = $scope.parentPage;
                                    const configId = $scope.configId;
                                    const allConfigs = $scope.allDashboardConfigs;
                                    const dashboard = $scope.model;

                                    sfxModal.open({
                                        templateUrl: removeDashboardFromGroupTemplateUrl,
                                        resolve: {
                                            params: () => {
                                                return {
                                                    parentPage,
                                                    configId,
                                                    allConfigs,
                                                    dashboard,
                                                };
                                            },
                                        },
                                        controller: 'RemoveDashboardFromGroupController',
                                    });
                                };
                            }

                            if ($scope.isSnapshot) {
                                currentUser
                                    .id()
                                    .then(function (userId) {
                                        if (
                                            userId !==
                                            ($scope.snapshot.lastUpdatedBy ||
                                                $scope.snapshot.creator)
                                        ) {
                                            return false;
                                        }
                                        return currentUser.preferences().then(function (prefs) {
                                            return prefs.sf_doNotShowDashboardSaveConfirmation;
                                        });
                                    })
                                    .then(function (doNotShow) {
                                        if (doNotShow) {
                                            return;
                                        }
                                        // remove this cleanup after JIRA APPS-644 is fixed
                                        if (routeChangeCallback) {
                                            routeChangeCallback();
                                        }
                                        routeChangeCallback = $rootScope.$on(
                                            '$locationChangeStart',
                                            function (event, newUrl) {
                                                // ignore confirmation if there are no charts or user is backing forth between snapshots/charts
                                                if (
                                                    newUrl.match(/signin/) ||
                                                    !$scope.model.charts.length ||
                                                    newUrl.match($scope.snapshot.id) ||
                                                    newUrl.match('/temp/dashboard') ||
                                                    newUrl.match('/chart/new')
                                                ) {
                                                    return;
                                                }
                                                event.preventDefault();
                                                confirmService
                                                    .confirm({
                                                        title: 'Dashboard Modified',
                                                        text: [
                                                            'You have not saved this dashboard, which expires in ' +
                                                                dateService.daysTo(
                                                                    $scope.snapshot.expiryMs
                                                                ) +
                                                                ' day(s) on ' +
                                                                moment(
                                                                    $scope.snapshot.expiryMs
                                                                ).format('MMM DD YYYY') +
                                                                '. Are you sure you want to leave this page?',
                                                            'Click Cancel to continue working or save it as a new dashboard.',
                                                        ],
                                                        doNotShowPreferenceName:
                                                            'sf_doNotShowDashboardSaveConfirmation',
                                                        noText: 'Cancel',
                                                        yesText: 'Leave',
                                                    })
                                                    .then(function (value) {
                                                        if (value) {
                                                            unregisterRouteChangeCallback();
                                                            $window.location.href = newUrl;
                                                        }
                                                    });
                                            }
                                        );
                                    })
                                    .catch(function (e) {
                                        $log.error(
                                            'Error processing snapshot for route change handling.',
                                            e
                                        );
                                    });
                                $scope.isWorkspace = newDashboardService.isWorkspace(
                                    $scope.snapshot
                                );
                            }

                            if ($scope.model) {
                                if ($scope.allDashboardConfigs) {
                                    $scope.allDashboardConfigs.then((configs) => {
                                        $scope.allConfigsForCurrentDashboard = configs;
                                    });
                                }

                                zeroStateService.queryForMetrics().then((hasNoMetrics) => {
                                    $scope.hasNoMetrics = hasNoMetrics;
                                });

                                crossLinkDataService.setCurrentContext($scope.model.id);
                            }
                        }

                        let routeChangeCallback;
                        $scope.cloneDashboard = function () {
                            let defaultTargetPage = $scope.parentPage;

                            if ($scope.isServiceDiscovery && !hasSuperpowers) {
                                defaultTargetPage = null;
                            }

                            const currentFilters = dashboardUtil.getCurrentFiltersFromURL();
                            const selectedEventOverlays =
                                urlOverridesService.getSelectedEventOverlays();
                            const dashboardToClone = angular.copy($scope.model);

                            dashboardToClone.filters =
                                dashboardVariablesService.mergeUrlValuesToFilterDefinitions(
                                    dashboardToClone.filters,
                                    currentFilters
                                );
                            dashboardToClone.selectedEventOverlays = selectedEventOverlays;

                            if (featureEnabled('accessControl')) {
                                const cloneAclDashboard = () =>
                                    aclDashboardCloner(
                                        dashboardToClone,
                                        getChartModels(),
                                        defaultTargetPage,
                                        routeChangeCallback
                                    );

                                if (defaultTargetPage) {
                                    return permissionsChecker
                                        .hasDashboardGroupWriteAccess(defaultTargetPage)
                                        .then((hasWriteAccessOnDG) => {
                                            if (!hasWriteAccessOnDG) {
                                                defaultTargetPage = null;
                                            }

                                            return cloneAclDashboard();
                                        });
                                } else {
                                    return cloneAclDashboard();
                                }
                            }

                            return dashboardCloner(
                                dashboardToClone,
                                getChartModels(),
                                $scope.snapshot,
                                defaultTargetPage,
                                routeChangeCallback
                            );
                        };

                        function filtersDirty() {
                            return (
                                $scope.model &&
                                $scope.model.charts.length &&
                                $scope.retainedDashboardState.filterState.sourceFilterDirty
                            );
                        }

                        $scope.addDashboardToGroup = function () {
                            const sourceFilters = filtersDirty()
                                ? urlOverridesService.getSourceFilterOverrideList()
                                : null;
                            const dashboard = angular.copy($scope.model);
                            const currentGroupId = $scope.parentPage?.id;

                            let modalView = {
                                controller: 'AddDashboardToGroupController',
                                templateUrl: addDashboardToGroupTemplateUrl,
                            };

                            if (featureEnabled('accessControl')) {
                                // declare-used-dependency-to-linter::saveAclDashboardModal
                                modalView = { component: 'saveAclDashboardModal' };
                            }

                            sfxModal
                                .open({
                                    ...modalView,
                                    resolve: {
                                        params: function () {
                                            return {
                                                dashboard,
                                                sourceFilters,
                                                currentGroupId,
                                                allCharts: getChartModels(),
                                                isMirror: true,
                                                onSuccess: (groupId, configId, filters) => {
                                                    if (filters) {
                                                        userAnalytics.event(
                                                            'dashboard-views',
                                                            'add-filter-overrides-from-add-to-modal'
                                                        );
                                                    }

                                                    const queryParamsForDashboardContext =
                                                        dashboardUtil.getDashboardSearchParamsString(
                                                            groupId,
                                                            configId
                                                        );
                                                    return $timeout(() => {
                                                        $location.url(
                                                            `/dashboard/${dashboard.id}?${queryParamsForDashboardContext}`
                                                        );
                                                    }, METABASE_CONSISTENCY_PRAYER_DURATION_MS);
                                                },
                                            };
                                        },
                                    },
                                    windowClass: 'add-dashboard-to-group-modal',
                                })
                                .result.then(() => {
                                    // always reload so we pick up new mirror state.
                                    ngRoute.reload();
                                });
                        };

                        $scope.saveTempDashboard = function () {
                            $scope.cloneDashboard().then(function () {
                                // Reset user preference for new dashboard
                                const update = {
                                    sf_newDashboard: '',
                                    sf_numUnseenCharts: 0,
                                };
                                return newDashboardService.updateNewDashboardInfo(update, {
                                    hasNewCharts: false,
                                });
                            });
                        };

                        // Remove all charts from the dashboard, after confirmation
                        $scope.clearTempDashboard = function () {
                            currentUser
                                .preferences()
                                .then(function (prefs) {
                                    if (prefs.sf_doNotShowClearChartsConfirmation) {
                                        return true;
                                    } else {
                                        return confirmService.confirm({
                                            title: 'Clear dashboard',
                                            text: [
                                                'This will permanently delete all charts on this dashboard.',
                                                'To keep from losing your work, click Cancel.',
                                            ],
                                            doNotShowPreferenceName:
                                                'sf_doNotShowClearChartsConfirmation',
                                            noText: 'Cancel',
                                            yesText: 'Clear',
                                        });
                                    }
                                })
                                .then(function (value) {
                                    if (value) {
                                        $scope.$broadcast('removeAllCharts');
                                    }
                                });
                        };

                        $scope.overlayEvents = function (overlayParams) {
                            $scope.eventOverlayParams = angular.copy(overlayParams);
                            //this is necessary due to some state crossover between eventpanel and eventoverlay
                            $scope.eventSidebarParams.eventQuery = overlayParams.eventQuery;
                            $scope.eventSidebarParams.eventOverlayColors =
                                overlayParams.eventOverlayColors;
                        };

                        $scope.$watch('model.locked', (newVal, oldVal) => {
                            // check to see we're not firing this on initialization, only transition
                            if (angular.isDefined(newVal) && angular.isDefined(oldVal)) {
                                postHierarchyInitialize();
                            }
                        });

                        const writePermissionsWatchGroup = [
                            'model.authorizedWriters',
                            'parentPage.sf_authorizedUserWriters',
                            'parentPage.sf_authorizedTeamWriters',
                        ];
                        $scope.$watchGroup(writePermissionsWatchGroup, (newVal, oldVal) => {
                            if (newVal !== oldVal) {
                                updateWritePermissionState($scope.model, $scope.parentPage);
                            }
                        });

                        function updateWritePermissionState(dashboard, group) {
                            permissionsChecker
                                .getHierarchyWritePermissions(dashboard, group, $scope.isSnapshot)
                                .then((result) => {
                                    $scope.hierarchyPermissions = result;
                                    $scope.hasWritePermission = result.hasWritePermission;
                                    $scope.hasGroupWritePermission = result.hasGroupWritePermission;
                                    $scope.hasGroupReadPermission = result.hasGroupReadPermission;
                                    $scope.canEditAllSiblingDashboards =
                                        result.canEditAllSiblingDashboards;
                                    $scope.canBeMirrored = result.canBeMirrored;
                                    $scope.canRemoveMirrors = result.canRemoveMirrors;

                                    if ($scope.canEditAllSiblingDashboards === undefined) {
                                        $scope.canEditAllSiblingDashboards =
                                            result.hasWritePermission;
                                    }

                                    postHierarchyInitialize();

                                    // Setting the permissions can hide or show parts of the dashboard group tabs that will change their width - need to remeasure and adjust the tabs
                                    $scope.$emit('dashboard tab update');

                                    // the point of this promise is to allow dashboard to render without waiting for its permissions
                                    // original: https://github.com/signalfx/signalview/commit/40d0b4963e77b0d03ab6604355b10e640575b58e
                                    return $timeout(() => {
                                        permissionsDeferred.resolve(true);
                                    }, 0);
                                });

                            permissionsChecker
                                .hasPermissionTo(
                                    PERMISSIONS_ACTIONS.DELETE,
                                    AccessControlObjectType.DASHBOARD,
                                    [dashboard, group],
                                    $scope.isSnapshot
                                )
                                .then((canDelete) => ($scope.canDelete = canDelete));
                        }

                        $scope.showPageInfo = function () {
                            dashboardGroupSettingsModal
                                .info($scope.parentPage, $scope.model)
                                .then(handleDashboardGroupUpdate)
                                .catch(handleSettingsModalError);
                        };

                        $scope.showPagePermissionsLink = function () {
                            return (
                                featureEnabled('writepermissions') ||
                                featureEnabled('accessControl')
                            );
                        };

                        $scope.showPermissionsLink = function () {
                            return (
                                showActionMenu() &&
                                (featureEnabled('writepermissions') ||
                                    featureEnabled('accessControl'))
                            );
                        };

                        $scope.showPagePermissions = function () {
                            dashboardGroupSettingsModal
                                .permissions($scope.parentPage, $scope.model)
                                .then(handleDashboardGroupUpdate)
                                .catch(handleSettingsModalError);
                        };

                        function handleDashboardGroupUpdate(dashboardGroup) {
                            $scope.parentPage = dashboardGroup;
                            // Should only happen when an update to the group has gone through
                            updateWritePermissionState($scope.model, $scope.parentPage);
                        }

                        function handleSettingsModalError(resp) {
                            if (resp && resp !== 'cancel') {
                                $log.error(resp);
                            }
                        }

                        $scope.canMakeReadOnly = function () {
                            return (
                                $scope.model &&
                                $scope.readOnlyEnabled &&
                                !$scope.model.locked &&
                                !$scope.isServiceDiscovery &&
                                !$scope.isSnapshot &&
                                !featureEnabled('writepermissions')
                            );
                        };

                        $scope.makeDashboardReadOnly = function () {
                            dashboardUtil
                                .makeDashboardReadOnly($scope.model)
                                .then((updatedDashboard) => {
                                    $scope.model.locked = updatedDashboard.locked;
                                    $scope.model.lastUpdated = updatedDashboard.lastUpdated;
                                    $scope.model.lastUpdatedBy = updatedDashboard.lastUpdatedBy;
                                });
                        };

                        $scope.canMakeEditable = function () {
                            return (
                                $scope.model &&
                                $scope.readOnlyEnabled &&
                                $scope.model.locked &&
                                !$scope.isServiceDiscovery &&
                                !$scope.isSnapshot
                            );
                        };

                        $scope.makeDashboardEditable = function () {
                            dashboardUtil
                                .makeDashboardEditable($scope.model)
                                .then((updatedDashboard) => {
                                    const lockingUser = userV2Service.getOrgMemberForUserId(
                                        $scope.model.lastUpdatedBy
                                    );
                                    return $q.all({
                                        lockingUser: lockingUser,
                                        updatedDashboard: updatedDashboard,
                                    });
                                })
                                .then((lockInfo) => {
                                    if (!featureEnabled('writepermissions')) {
                                        appNotificationService.add({
                                            message: `This dashboard (originally made read-only on ${$filter(
                                                'dateWithTimeZone'
                                            )($scope.model.lastUpdated, 'longDate')}${
                                                lockInfo.lockingUser
                                                    ? ' by ' + lockInfo.lockingUser.fullName
                                                    : ''
                                            }) is now editable by everyone.`,
                                            messageClass: 'info',
                                            callToAction: {
                                                text: 'Make Read-Only',
                                                btnClassName: 'read-only-btn',
                                                callback: () => {
                                                    const reLockedDash =
                                                        dashboardUtil.makeDashboardReadOnly(
                                                            lockInfo.updatedDashboard
                                                        );
                                                    reLockedDash.then((dash) => {
                                                        $scope.model.locked = dash.locked;
                                                    });
                                                },
                                                dismissOnClick: true,
                                            },
                                            userDismissable: true,
                                            global: false,
                                        });
                                    }

                                    return lockInfo.updatedDashboard;
                                })
                                .then((updatedDashboard) => {
                                    $scope.model.locked = updatedDashboard.locked;
                                    $scope.model.lastUpdated = updatedDashboard.lastUpdated;
                                    $scope.model.lastUpdatedBy = updatedDashboard.lastUpdatedBy;
                                });
                        };

                        $scope.showTeamsLinksModal = function () {
                            return pageService.teamRelationEditor($scope.parentPage);
                        };

                        $scope.snapshot = $scope.snapshot || {};

                        function isUserDashboardGroup() {
                            return (
                                pageTypeService.getType($scope.parentPage) ===
                                pageTypeService.getTypes().USER
                            );
                        }

                        $scope.isTeamsLinksDisabled = function () {
                            return (
                                $scope.isSnapshot ||
                                isUserDashboardGroup() ||
                                !$scope.hasGroupWritePermission
                            );
                        };

                        $scope.userHasGroupWritePermission = function () {
                            return $scope.hasGroupWritePermission;
                        };

                        $scope.showDeleteDashboardGroup = function () {
                            return (
                                !$scope.isSnapshot &&
                                !$scope.isServiceDiscovery &&
                                !isUserDashboardGroup()
                            );
                        };

                        $scope.deleteDashboardGroupEnabled = function () {
                            if (
                                $scope.canEditAllSiblingDashboards &&
                                $scope.hasGroupWritePermission
                            ) {
                                return true;
                            }

                            const dashboards =
                                $scope.parentPage.dashboards || $scope.parentPage.sf_dashboards;
                            return (
                                dashboards &&
                                dashboards.length === 0 &&
                                $scope.hasGroupWritePermission
                            );
                        };

                        function handleDashboardGroupDelete() {
                            closeAndClearModal();
                            return pageService.deleteDashboardGroup($scope.parentPage);
                        }

                        $scope.deleteDashboardGroup = function () {
                            const page = $scope.parentPage;

                            $scope.deleteModalState.onDelete = function () {
                                $timeout(handleDashboardGroupDelete, 0);
                            };

                            $scope.deleteModalState.title = 'Delete dashboard group';
                            $scope.deleteModalState.isLoading = true;
                            $scope.deleteModalState.isOpen = true;
                            $scope.deleteModalState.callToAction = 'Delete';

                            dashboardGroupService
                                .getDashboards(page.sf_id || page.id)
                                .then((response) => {
                                    $timeout(function () {
                                        const dashboards = response.dashboards;
                                        $scope.deleteModalState.description =
                                            'You are about to permanently delete the dashboard group ' +
                                            pageDisplayTitle(page) +
                                            ', as well as all its dashboards and their charts.';

                                        $scope.deleteModalState.listElements = (
                                            dashboards || []
                                        ).map((dashboard) => ({
                                            name: dashboard.name || dashboard.sf_dashboard,
                                            link:
                                                '#/dashboard/' + (dashboard.id || dashboard.sf_id),
                                        }));
                                        if (dashboards.length) {
                                            $scope.deleteModalState.listTitle =
                                                'The following dashboards will be deleted:';
                                            $scope.deleteModalState.callToAction =
                                                'Delete dashboard group and its dashboards';
                                            $scope.deleteModalState.typeConfirmation = true;
                                        }

                                        $scope.deleteModalState.isLoading = false;
                                    }, 0);
                                })
                                .catch(function (e) {
                                    $log.error('Failed fetching page dashboards.', e);
                                    return [];
                                });
                        };

                        function showDashboardCrossLinks(crossLinkParams) {
                            dashboardSettingsModal
                                .crossLinks(getDashboardSettingsModalParams(), crossLinkParams)
                                .then(hardReloadIfSaved)
                                .catch(hardReloadIfSaved);
                        }
                        $scope.showDashboardCrossLinks = showDashboardCrossLinks;

                        function getDashboardSettingsModalParams() {
                            return {
                                dashboard: $scope.model,
                                isMirror: $scope.isMirror,
                                charts: getChartModels(),
                                snapshot: $scope.snapshot,
                                updateVariables,
                                updateVariablesOverrides,
                                updateDashboardInfo,
                                allDashboardConfigs: $scope.allDashboardConfigs,
                                // TODO(trevor): Remove sf_id when v1 hierarchy gets removed after v2 chart migrations are finished
                                currentGroupId: $scope.parentPage?.id || $scope.parentPage?.sf_id,
                                currentGroupName: $scope.parentPage?.name,
                                currentConfig: $scope.activeDashboardConfig,
                                userHasGroupWritePermission:
                                    $scope.hasGroupWritePermission &&
                                    $scope.parentPage.creator !== SYSTEM_USER_ID,
                            };
                        }

                        function hardReloadIfSaved(msg) {
                            if (msg && msg.saved) {
                                $scope.retainedDashboardState.reset();
                                ngRoute.reload();
                            }
                        }

                        $scope.showDashboardInfo = function () {
                            dashboardSettingsModal
                                .info(getDashboardSettingsModalParams())
                                .then(hardReloadIfSaved)
                                .catch(hardReloadIfSaved);
                        };

                        $scope.showRenameModal = function () {
                            dashboardSettingsModal
                                .rename(getDashboardSettingsModalParams())
                                .then(hardReloadIfSaved)
                                .catch(hardReloadIfSaved);
                        };

                        $scope.showEditVariablesModal = function () {
                            dashboardSettingsModal
                                .variables(getDashboardSettingsModalParams())
                                .then(hardReloadIfSaved)
                                .catch(hardReloadIfSaved);
                        };

                        $scope.showEditPermissionsModal = function () {
                            dashboardSettingsModal
                                .permissions(getDashboardSettingsModalParams())
                                .then(hardReloadIfSaved)
                                .catch(hardReloadIfSaved);
                        };

                        $scope.showEventOverlayModal = function () {
                            dashboardSettingsModal
                                .eventOverlay(getDashboardSettingsModalParams())
                                .then(hardReloadIfSaved)
                                .catch(hardReloadIfSaved);
                        };

                        $scope.dataLinksEnabled = function () {
                            return showActionMenu();
                        };

                        $scope.canEditGroup = function () {
                            return (
                                $scope.hasGroupWritePermission && !$scope.groupIsServiceDiscovery
                            );
                        };

                        $scope.exportV2DashboardGroups = function () {
                            const name =
                                ($scope.parentPage.sf_page || $scope.parentPage.name) + '.json';
                            const content = dashboardGroupService.exportGroup(
                                $scope.parentPage.sf_id || $scope.parentPage.id
                            );
                            return download(name, content, 'Dashboard Group');
                        };

                        $scope.exportV2Dashboard = function () {
                            const name = `dashboard_${$scope.model.name}.json`;
                            const content = dashboardV2Service.export($scope.model.id);
                            return download(name, content, 'Dashboard');
                        };

                        $scope.exportV2DashboardGroupEnabled = function () {
                            return $scope.parentPage !== null && !$scope.isSnapshot;
                        };

                        $scope.chartModel = $scope.chart;

                        $scope.chartUrlGenerator = function (resolveVariables) {
                            let chartUrl;
                            if (resolveVariables) {
                                chartUrl = $scope.chartFiltersLinkNoVariables || '';
                            } else {
                                chartUrl = $scope.chartFiltersLink || '';
                            }

                            const group = $scope.parentPage || {};
                            const pageId = group.id || group.sf_id;
                            const viewParams = dashboardUtil.getDashboardSearchParamsString(
                                pageId,
                                $scope.configId
                            );

                            return viewParams.length > 0 ? `${viewParams}&${chartUrl}` : chartUrl;
                        };

                        $scope.saveFilters = function () {
                            $scope.retainedDashboardState.save().then(function () {
                                ngRoute.reload();
                            });
                        };

                        function getChartModels() {
                            return dashboardUtil.getAllChartModels(
                                $scope.retainedDashboardState.allCharts
                            );
                        }

                        $scope.share = function () {
                            sharingService.shareDashboard(
                                $scope.model,
                                getChartModels(),
                                $scope.parentPage,
                                $scope.snapshot
                            );
                        };

                        $scope.hasCharts = hasCharts;

                        $scope.export = function () {
                            return $scope.exportV2Dashboard();
                        };

                        $scope.exportEnabled = function () {
                            return $scope.model !== null && !$scope.isSnapshot;
                        };

                        $scope.exportGroup = function () {
                            return $scope.exportV2DashboardGroups();
                        };

                        $scope.viewFullscreen = fullscreen.requestFullscreen;
                        $scope.isFullscreen = fullscreen.isFullscreen;

                        let lastComputedNonGlobalRange = null;

                        $scope.getCurrentTimeRange = function () {
                            let start = null;
                            let end = null;
                            const globalTime = urlOverridesService.getGlobalTimeAbsolute();
                            if (globalTime) {
                                start = globalTime.start;
                                end = globalTime.end;
                                return {
                                    start,
                                    end,
                                };
                            } else {
                                // obtain the minimum start time and maximum end time of a dashboard's charts
                                if (lastComputedNonGlobalRange === null) {
                                    lastComputedNonGlobalRange =
                                        dashboardUtil.getTimeSpanForAllCharts(getChartModels());
                                }
                                return lastComputedNonGlobalRange;
                            }
                        };

                        // Close right sidebars when left one is opened
                        $scope.$on('show catalog sidebar', function () {
                            $scope.closeMetricsSidebar();
                        });

                        $scope.pageDisplayTitle = '';

                        function setPageName() {
                            if ($scope.isSnapshot) {
                                $scope.pageDisplayTitle = 'Unsaved dashboard';
                            } else {
                                $scope.pageDisplayTitle = pageDisplayTitle($scope.parentPage);
                            }
                        }

                        $scope.$watch('parentPage.sf_page', setPageName);
                        $scope.$watch('parentPage.name', setPageName);

                        $scope.chartFiltersLink = '';
                        $scope.chartFiltersLinkNoVariables = '';

                        function updateFilterAlias(newValue) {
                            if (!$scope.model.filters) {
                                $scope.model.filters = {};
                            }

                            $scope.model.filters.variables = newValue || [];
                        }

                        function updateFilterAliasOverrides(newValue) {
                            if (!$scope.activeDashboardConfig.filtersOverride) {
                                $scope.activeDashboardConfig.filtersOverride = {};
                            }
                            $scope.activeDashboardConfig.filtersOverride.variables = newValue || [];
                        }

                        function updateChartFiltersLink() {
                            const variables = safeLookup($scope, 'model.filters.variables') || [];
                            const mirrorOverrides =
                                safeLookup(
                                    $scope,
                                    'activeDashboardConfig.filtersOverride.variables'
                                ) || [];
                            const merged = dashboardVariableUtils.mergeMirrorVariables(
                                variables,
                                mirrorOverrides
                            );
                            $scope.chartFiltersLink = chartUtils.getChartFiltersLink(merged);
                            $scope.chartFiltersLinkNoVariables = chartUtils.getChartFiltersLink(
                                merged,
                                true
                            );
                        }

                        updateChartFiltersLink();
                        $scope.$on('React:$routeUpdate', updateChartFiltersLink);

                        function hasCharts() {
                            const dashboard = $scope.model || {};
                            const uiModel = dashboard.charts || [];
                            return uiModel && uiModel.length;
                        }

                        function showActionMenu() {
                            return (
                                $scope.model &&
                                (!$scope.isServiceDiscovery ||
                                    ($scope.isServiceDiscovery &&
                                        $scope.isMirrorOfServiceDiscovery()) ||
                                    hasSuperpowers) &&
                                !$scope.isSnapshot
                            );
                        }
                        $scope.showActionMenu = showActionMenu;

                        function enableCustomDashboardAsHomePage() {
                            return $scope.model && $scope.customDashboardAsHomePageEnabled;
                        }
                        $scope.enableCustomDashboardAsHomePage = enableCustomDashboardAsHomePage;

                        function setAsHomepage() {
                            userAnalytics.event(
                                'Data_View/Dashboard_Actions',
                                'click',
                                null,
                                'Set_As_Home_Page'
                            );
                            let homepageKeyInfo;
                            dashboardUtil
                                .getAllDashboardConfigs($scope.model.id)
                                .then((configs) => {
                                    const config = dashboardUtil.getConfigForDashboard(
                                        configs,
                                        $scope.model.id
                                    );

                                    if (config.configId && $scope.model.groupId) {
                                        // configId and groupId need to be present for mirrored dashboards
                                        homepageKeyInfo = {
                                            category: 'mirror',
                                            dashboardId: $scope.model.id,
                                            configId: config.configId,
                                            groupId: $scope.model.groupId,
                                        };
                                    } else if ($scope.model.groupName && $scope.model.name) {
                                        // built in dashboard
                                        homepageKeyInfo = {
                                            category: 'builtin',
                                            dashboardGroupName: $scope.model.groupName,
                                            dashboardName: $scope.model.name,
                                        };
                                    } else {
                                        // default assume dashboard is custom
                                        homepageKeyInfo = {
                                            category: 'custom',
                                            dashboardId: $scope.model.id,
                                        };
                                    }
                                    homepageV2Service.setDashboardAsHomepage(homepageKeyInfo).then(
                                        () => {
                                            NotificationService.post({
                                                category: NotificationCategory.TOAST,
                                                subcategory: 'Set Homepage',
                                                message: `Next time you navigate to the observability home page, you will see this dashboard.`,
                                                title: `Dashboard set as Home Page`,
                                                type: 'success',
                                                shouldDisappear: true,
                                                onRemove: () => {},
                                            });
                                        },
                                        (e) => {
                                            $log.error(
                                                'There was an error in setting the dashboard as homepage',
                                                e
                                            );
                                            NotificationService.post({
                                                category: NotificationCategory.TOAST,
                                                subcategory: 'Set Homepage',
                                                message: `Please try again.`,
                                                title: `Dashboard was not set as Home Page`,
                                                type: 'error',
                                                shouldDisappear: true,
                                                onRemove: () => {},
                                            });
                                        }
                                    );
                                });
                        }
                        $scope.setAsHomepage = setAsHomepage;

                        function unregisterRouteChangeCallback() {
                            const tmp = routeChangeCallback;
                            routeChangeCallback = null;
                            if (tmp) {
                                tmp();
                            }
                        }

                        function updateVariables(value) {
                            if (value) {
                                updateFilterAlias(value);
                                let promise = $q.when();
                                if ($scope.isSnapshot) {
                                    promise = $scope.retainedDashboardState.saveWorkspace();
                                }
                                promise.then(function () {
                                    variableUpdateCleanup(value);
                                });
                            }
                        }

                        function updateVariablesOverrides(value) {
                            if (value) {
                                updateFilterAliasOverrides(value);

                                // We can be certain that this is not a snapshot because this will only be called for a
                                // dashboard with multiple views
                                variableUpdateCleanup(value);
                            }
                        }

                        function variableUpdateCleanup(value) {
                            dashboardVariablesService.setVariablesOverride(value);
                            $scope.retainedDashboardState.filterState.variablesDirty = false;
                            $timeout(function () {
                                $scope.$broadcast('updateVariables', { reset: true });
                            }, 0);
                        }

                        function updateDashboardInfo(infoUpdated) {
                            if (!$scope.isSnapshot) {
                                return;
                            }

                            // if name or description was modified, update snapshot
                            if (infoUpdated) {
                                return $scope.retainedDashboardState.saveWorkspace();
                            }
                        }

                        function generateDashboardUpdateNotification() {
                            if (
                                $scope.dashboardViewsEnabled &&
                                $scope.appearanceCount > 1 &&
                                !dashboardViewBannerShown
                            ) {
                                const mirrorOrMirrors =
                                    $scope.appearanceCount === 1 ? 'mirror' : 'mirrors';
                                const groupOrGroups = $scope.groupCount === 1 ? 'group' : 'groups';
                                appNotificationService.add({
                                    message: `Your changes to this dashboard will be seen in all of its mirrors. This dashboard has ${$scope.appearanceCount} ${mirrorOrMirrors} in ${$scope.groupCount} ${groupOrGroups}.`,
                                    messageClass: 'info',
                                    userDismissable: true,
                                    global: false,
                                });

                                dashboardViewBannerShown = true;
                            }
                        }
                        $scope.variableSearchParams = {};
                        /**
                         * watch on variable[], startTime and endTime search params changes
                         * It updates the suggestions of the dashboard variable once the search params changes
                         */
                        function watchSearchParams() {
                            let variableOverrides = ngRoute.params['variables[]'];
                            const timeOverrides =
                                ngRoute.params['startTime'] && ngRoute.params['endTime'];
                            if (variableOverrides) {
                                variableOverrides = Array.isArray(variableOverrides)
                                    ? variableOverrides
                                    : [variableOverrides];
                                const parsedUrlVariables = variableOverrides.map(
                                    (variableOverride) =>
                                        dashboardVariableUtils.getVariableFromStr(variableOverride)
                                );
                                $scope.variableSearchParams = { variables: parsedUrlVariables };
                            }
                            if (timeOverrides) {
                                $scope.variableSearchParams = {
                                    ...$scope.variableSearchParams,
                                    currentTimeRange: $scope.getCurrentTimeRange(),
                                };
                            }

                            if (!variableOverrides && !timeOverrides) {
                                $scope.variableSearchParams = {};
                            }
                        }

                        $scope.$on('React:$routeUpdate', () => {
                            if ($scope.dashboardViewsEnabled) {
                                const sameDashboardDifferentGroup =
                                    $scope.urlGroupId &&
                                    ngRoute.params.groupId &&
                                    $scope.urlGroupId !== ngRoute.params.groupId;
                                const sameDashboardSameGroup =
                                    $scope.urlConfigId &&
                                    ngRoute.params.configId &&
                                    $scope.urlConfigId !== ngRoute.params.configId;
                                if (sameDashboardDifferentGroup || sameDashboardSameGroup) {
                                    ngRoute.reload();
                                }
                            }
                            if ($scope.ollyFilterbarEnabled) {
                                watchSearchParams();
                            }
                        });

                        $scope.$on('jqui-drag-start', function () {
                            if (!$scope.hasGroupWritePermission) {
                                $scope.showNoDragPermission = true;
                            } else {
                                $scope.showNoDragPermission = false;
                            }
                        });

                        $scope.$on('jqui-drag-stop', function () {
                            $scope.showNoDragPermission = false;
                        });

                        $scope.$on('broadcast url overrides', (ev, data) => {
                            $scope.$broadcast('dashboard.updateOverrides', data);
                        });

                        $scope.$on('showDashboardVerticalLines', function (evt, timestamp) {
                            $scope.$broadcast('showChartsVerticalLines', timestamp);
                        });

                        $scope.$on('hideDashboardVerticalLines', function () {
                            $scope.$broadcast('hideChartsVerticalLines');
                        });

                        $scope.$on('$destroy', function () {
                            unregisterRouteChangeCallback();
                        });

                        $scope.$on('new workspace', unregisterRouteChangeCallback);

                        $scope.$on('dashboard layout changed', generateDashboardUpdateNotification);
                    },
                ],
            };
        },
    ]);
