import detectorV2SettingsPanelTemplateUrl from './detectorV2SettingsPanel.tpl.html';
import preflightInfoPanelTemplateUrl from '../../detector/preflightInfoPanel.tpl.html';
import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';
import { NO_CAPABILITIES_TOOLTIP } from '../../../common/data/rbac/common';

angular.module('signalview.detectorV2').controller('v2DetectorWizardModalController', [
    '$scope',
    '$uibModalInstance',
    '$timeout',
    '$window',
    '$log',
    'data',
    'notifyBlockService',
    'confirmService',
    'v2DetectorAPIWrapper',
    'timeToRange',
    'uiModelToVisualizationOptionsService',
    'DETECTOR_CHART_IDENTIFIERS',
    'CHART_DISPLAY_EVENTS',
    'ChartDisplayDebounceService',
    'detectorUtils',
    'hasCapability',
    'localStorage',
    function (
        $scope,
        $uibModalInstance,
        $timeout,
        $window,
        $log,
        data,
        notifyBlockService,
        confirmService,
        v2DetectorAPIWrapper,
        timeToRange,
        uiModelToVisualizationOptionsService,
        DETECTOR_CHART_IDENTIFIERS,
        CHART_DISPLAY_EVENTS,
        ChartDisplayDebounceService,
        detectorUtils,
        hasCapability,
        localStorage
    ) {
        $scope.preflightInfoPanelTemplateUrl = preflightInfoPanelTemplateUrl;
        $scope.isV2Detector = true;
        $scope.detectorChartIdentifiers = DETECTOR_CHART_IDENTIFIERS;
        $scope.detectorV2SettingsPanelTemplateUrl = detectorV2SettingsPanelTemplateUrl;
        $scope.detector = data.detector;
        $scope.model = data.model;
        $scope.isFlow2 = true;
        $scope.rule = data.rule;
        $scope.detectorPreviewConfig = getDetectorPreviewConfig(data.rule);
        $scope.hasWritePermission = data.hasWritePermission;
        $scope.isNewRule = data.isNewRule;
        $scope.preSelectedPlot = null;
        $scope.plots = [];
        $scope.chartDisplayDebouncer = new ChartDisplayDebounceService();
        $scope.chartDisplayDebouncer.setEnabled(false);
        $scope.getDisabledActivationTooltipText = getDisabledActivationTooltipText;

        $scope.showArchivedMetricsWarning = localStorage.getItem(
            'imm.debug.archived-metrics-warning'
        );

        $scope.timezone = {
            query: $scope.model.sf_timezone || '',
            callback: (toSave) => {
                $scope.hasUnsavedChanges = true;
                $scope.model.sf_timezone = toSave;
                $scope.model.sf_uiModel.chartconfig.timezone = toSave;
                $scope.detector.timezone = toSave;
            },
        };
        $scope.stages = [
            {
                key: 'signalFlow',
                name: 'SignalFlow',
                summary: function () {
                    return `Rule defined by SignalFlow label: ${$scope.rule.detectLabel}`;
                },
                isCompleted: isVisited,
            },
            {
                key: 'message',
                name: 'Alert message',
                isCompleted: isVisited,
            },
            {
                key: 'recipients',
                name: 'Alert recipients',
                summary: function () {
                    return (
                        ($scope.rule.notifications || []).map(notifyBlockService).join(', ') ||
                        'No recipients'
                    );
                },
                isCompleted: isVisited,
            },
            {
                key: 'activate',
                name: 'Activate...',
                isCompleted: isVisited,
            },
        ];

        $scope.$on('v2 signalflow updated', (ev, plotLabels) => {
            detectorUtils.getPlotsFromProgramText($scope.detector.programText).then((plots) => {
                // Update model with new plot and plot label information
                let visualizationOptions;
                if ($scope.model.sf_uiModel) {
                    visualizationOptions = uiModelToVisualizationOptionsService(
                        $scope.model.sf_uiModel
                    ).serialize();
                }

                const plotModel = detectorUtils.getV2DetectorPlotModel(
                    $scope.detector.name,
                    plots,
                    plotLabels,
                    visualizationOptions
                );
                $scope.model.sf_uiModel = angular.extend($scope.model.sf_uiModel, plotModel);
            });
        });

        const signalflow_tab = { id: 'signalflow', name: 'Signalflow' };
        const options_tab = { id: 'options', name: 'Options' };
        const events_tab = { id: 'events', name: 'Simulated Events' };
        const data_table_tab = { id: 'data', name: 'Data Table' };
        $scope.sectionTabs = [signalflow_tab, options_tab, events_tab, data_table_tab];

        $scope.stageKeys = {};
        $scope.stages.forEach(function (s, i) {
            $scope.stageKeys[s.key] = i;
        });

        $scope.hasUnsavedChanges = false;
        setupWatchers();

        $scope.onSignalFlowChange = function (signalFlow) {
            $scope.detector.programText = signalFlow;
            $scope.hasUnsavedChanges = true;
            $scope.model.sf_viewProgramText = signalFlow;
        };

        $scope.setSignalFlowErrors = function (errors) {
            $scope.signalFlowErrors = errors;
        };

        hasCapability(Capability.UPDATE_DETECTOR).then((hasUpdateDetectorCapability) => {
            $scope.hasUpdateDetectorCapability = hasUpdateDetectorCapability;
        });

        let hasLabelMismatched;
        $scope.onKnownLabelsChange = function (knownLabels) {
            hasLabelMismatched = knownLabels.indexOf($scope.rule.detectLabel) < 0;
        };

        $scope.onSignalFlowParsed = function () {
            if ($scope.hasUnsavedChanges) {
                $scope.$broadcast('preflight trigger');
                $scope.$broadcast('rule publish preview', $scope.rule, true);
            }
        };

        $scope.updateAlertRule = function () {
            // swap rule in-place so we don't mess up ordering
            let ruleIndex = 0;
            let isRule = false;
            $scope.detector.rules.some(function (rule, index) {
                if (rule.detectLabel === $scope.rule.detectLabel) {
                    $scope.detector.rules[index] = $scope.rule;
                    ruleIndex = index;
                    isRule = true;
                    return true;
                }
            });
            // If a corresponding rule doesn't already exist, create
            // it so that we can persist any changes to severity,
            // description, etc... that the user may have made.
            if (!isRule) {
                $scope.model.policies.some((policy) => {
                    if ($scope.rule.detectLabel === policy.detectLabel) {
                        $scope.detector.rules.push($scope.rule);
                        ruleIndex = $scope.detector.rules.length - 1;
                        return true;
                    }
                });
            }

            const sanitizedDetector = getSanitizedDetector(ruleIndex);

            const wrappedDetector = v2DetectorAPIWrapper(sanitizedDetector);
            wrappedDetector.updatePublishLabelOptions($scope.model.sf_uiModel);

            return wrappedDetector
                .save()
                .then((result) => {
                    $uibModalInstance.close(result.data);
                })
                .catch(function (err) {
                    $log.error('Failed saving detector.', err);
                    $window.alert('There was an error saving this detector rule.');
                    throw new Error(err);
                });
        };

        function getSanitizedDetector(ruleIndex) {
            const detectorCopy = angular.copy($scope.detector);
            delete detectorCopy.rules[ruleIndex].isCustomizedMessage;
            return detectorCopy;
        }

        function isVisited(index) {
            return !$scope.isNewRule || $scope.stages[index].visited;
        }

        $scope.closeWizard = function () {
            if (!$scope.hasUnsavedChanges) {
                $uibModalInstance.dismiss();
            } else {
                const confirmModal = confirmService.confirm({
                    title: 'Discard Changes',
                    text: [
                        'There are unsaved changes.',
                        'Are you sure you want to discard these changes?',
                        "To save this rule, see the 'Activate' tab.",
                    ],
                    yesText: 'Yes discard the changes',
                    noText: 'Cancel',
                    danger: false,
                });
                confirmModal.then((yesDiscard) => {
                    if (yesDiscard) {
                        $uibModalInstance.dismiss();
                    }
                });
            }
        };

        function hasSignalflowErrors() {
            return $scope.signalFlowErrors || hasLabelMismatched;
        }

        // function that selects a passed in stage
        $scope.selectStage = function (stageIndex) {
            if ($scope.selectedStage === stageIndex) {
                return;
            }
            if (hasSignalflowErrors() && $scope.stages[stageIndex].key !== 'signalFlow') {
                showSignaFlowErrorModal();
                return;
            }
            if ($scope.stages[$scope.selectedStage]) {
                $scope.stages[$scope.selectedStage].visited = true;
            }
            $scope.selectedStage = stageIndex;
            const stageKey = getCurrentStageKey();
            $scope.isActivateStage = stageKey === 'activate';

            $scope.$broadcast('detector wizard selected stage', stageKey);
        };

        function selectInitialStage() {
            let initialStage;
            if ($scope.isNewRule) {
                initialStage = 0;
            } else {
                initialStage = $scope.stageKeys.activate;
            }
            $scope.selectStage(initialStage);
        }

        // Set initial stage
        selectInitialStage();

        $scope.goToNextStage = function () {
            if (hasSignalflowErrors()) {
                showSignaFlowErrorModal();
                return;
            }
            const nextStage = $scope.selectedStage + 1;
            $scope.selectStage(nextStage);
        };

        $scope.showSummary = function () {
            if (hasSignalflowErrors()) {
                showSignaFlowErrorModal();
                return;
            }
            $scope.selectStage($scope.stageKeys.activate);
        };

        $scope.passChartAndEventInformation = function (result) {
            $scope.$broadcast('pass chart and event information', result);
        };

        $scope.fetchChartData = function () {
            $scope.$broadcast('get events and metadata with timestamp');
        };

        $scope.$on(CHART_DISPLAY_EVENTS.JOB_FEEDBACK_RECEIVED, function (evt, msgs, identifier) {
            if (identifier === DETECTOR_CHART_IDENTIFIERS.NATIVE) {
                msgs.forEach(function (msg) {
                    if (msg.messageCode === 'DETECT_INPUT_CONTEXTS') {
                        $scope.alertSignalInputs = getAllSignalIdentifiers(msg);
                    }
                });
                // fetch message information as soon as chart has begun loading
                $scope.fetchChartData();
            }
        });

        $scope.$on(
            CHART_DISPLAY_EVENTS.CHART_WINDOW_MISALIGNED,
            function (evt, chartWindowMisaligned, identifier) {
                if (identifier === 'main') {
                    $scope.misalignedResolution = chartWindowMisaligned;
                }
            }
        );

        function getAllSignalIdentifiers(msg) {
            const inputs = [];
            const msgInput = msg.contents.inputContexts;
            Object.values(msgInput).forEach((msg) => {
                // check to see if there are labels which contain the rule label
                if (
                    msg.labels &&
                    msg.labels.includes($scope.rule.detectLabel) &&
                    Array.isArray(msg.identifiers)
                ) {
                    inputs.push(...msg.identifiers);
                }
            });
            return inputs;
        }

        // when the message component has values modified, this function callback sets the values
        // in the detector model
        $scope.onMessageSelect = function (
            severityLevel,
            runbookUrl,
            tip,
            messageSubject,
            messageBody
        ) {
            $scope.rule.severity = severityLevel;
            $scope.rule.runbookUrl = runbookUrl || '';
            $scope.rule.tip = tip || '';
            if (messageSubject) {
                $scope.rule.parameterizedSubject = messageSubject;
            }
            if (messageBody) {
                $scope.rule.parameterizedBody = messageBody;
            }
        };

        $scope.clearMessage = function (clearMessageBody, clearMessageSubject) {
            if (clearMessageBody) {
                $scope.rule.parameterizedBody = '';
            }
            if (clearMessageSubject) {
                $scope.rule.parameterizedSubject = '';
            }
        };

        $scope.$on('timePickerChanged', function (ev, timeobj) {
            const timeRange = timeToRange(timeobj);
            const v2TimeObject = detectorUtils.convertV1ToV2Time(timeRange);

            if (!_.isMatch($scope.detector.visualizationOptions.time, v2TimeObject)) {
                // model.sf_uiModel.chartconfig is consumed by the chart component
                angular.extend($scope.model.sf_uiModel.chartconfig, timeRange);
                $scope.detector.visualizationOptions.time = v2TimeObject;

                $scope.$broadcast('preflight trigger');
                $scope.$broadcast('rule publish preview', $scope.rule);
            }
        });

        $scope.onRecipientsSelect = function (recipients) {
            $scope.rule.notifications = recipients;
        };

        $scope.$on('tab selected', function (ev, tabId) {
            if (tabId === data_table_tab.id) {
                $scope.$broadcast(CHART_DISPLAY_EVENTS.LEGEND_TAB_SELECTED, 'data');
            } else if (tabId === events_tab.id) {
                $scope.$broadcast(CHART_DISPLAY_EVENTS.LEGEND_TAB_SELECTED, 'event');
            }
        });

        function getCurrentStageKey() {
            return ($scope.stages[$scope.selectedStage] || {}).key;
        }

        function showSignaFlowErrorModal() {
            let text, title;
            if (hasLabelMismatched) {
                title = 'Label Assignment Required';
                text = `Please assign the label ${$scope.rule.detectLabel} to a detect block. You can change this label in the SignalFlow tab when you have finished editing this rule.`;
            } else {
                title = 'SignalFlow Error';
                text = 'There is an error in your SignalFlow. Please fix it before proceeding.';
            }
            confirmService.confirm({
                title: title,
                text: [text],
                yesText: 'OK',
                noText: '',
                danger: false,
            });
        }

        function setupWatchers() {
            $timeout(function () {
                const watchHandle = $scope.$watch(
                    'rule',
                    function (nval, oval) {
                        $scope.hasUnsavedChanges = nval && oval && !angular.equals(nval, oval);
                        if ($scope.hasUnsavedChanges) {
                            watchHandle(); // no need to keep tracking since this dirty state is final, in the wizard
                        }
                    },
                    true
                );
            }, 500);

            $scope.$watchCollection(
                'model.sf_uiModel.chartconfig',
                function (nval, oval) {
                    if (!angular.equals(nval, oval)) {
                        $scope.hasUnsavedChanges = true;
                        setVisualizationOptions();
                    }
                },
                true
            );

            $scope.$watch(
                'model.sf_jobMaxDelay',
                function (nval, oval) {
                    if (!angular.equals(nval, oval)) {
                        $scope.hasUnsavedChanges = true;
                        $scope.model.sf_uiModel.chartconfig.maxDelay = nval;
                        $scope.detector.maxDelay = nval;
                    }
                },
                true
            );
        }

        function getDisabledActivationTooltipText() {
            if (!$scope.hasUpdateDetectorCapability) {
                return NO_CAPABILITIES_TOOLTIP;
            } else if (!$scope.hasWritePermission) {
                return "You cannot update the alert rule because you don't have write permission for this detector.";
            }
            return '';
        }

        function setVisualizationOptions() {
            if (!$scope.detector.visualizationOptions) {
                $scope.detector.visualizationOptions = {};
            }
            $scope.detector.visualizationOptions.showDataMarkers =
                safeLookup($scope.model, 'sf_uiModel.chartconfig.showDots') || false;
            $scope.detector.visualizationOptions.showEventLines =
                safeLookup($scope.model, 'sf_uiModel.chartconfig.eventLines') || false;
            $scope.detector.visualizationOptions.disableSampling =
                safeLookup($scope.model, 'sf_uiModel.chartconfig.disableThrottle') || false;
        }

        function getDetectorPreviewConfig(rule) {
            return {
                preview: {
                    rules: [rule],
                },
                isPreflight: true,
                detectLabel: rule.detectLabel,
            };
        }
    },
]);
