import { safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
import { getReservedDimensionKeys } from '@splunk/olly-utilities/lib/Timeseries';

export const chartDisplayUtils = [
    'featureEnabled',
    'urlOverridesService',
    'sourceFilterService',
    'programTextUtils',
    'plotUtils',
    'timepickerUtils',
    'chartUtils',
    'visualizationOptionsToUIModel',
    '$http',
    '$q',
    'API_URL',
    'murmurHash',
    '$log',
    'SAMPLE_CONSTANTS',
    'chartbuilderUtil',
    'dashboardVariablesService',
    function (
        featureEnabled,
        urlOverridesService,
        sourceFilterService,
        programTextUtils,
        plotUtils,
        timepickerUtils,
        chartUtils,
        visualizationOptionsToUIModel,
        $http,
        $q,
        API_URL,
        murmurHash,
        $log,
        SAMPLE_CONSTANTS,
        chartbuilderUtil,
        dashboardVariablesService
    ) {
        function isTimeSliceMode(uiModel) {
            return isTimeSliceModeFromMode(uiModel.chartMode);
        }

        function isTimeSliceModeFromMode(mode) {
            switch (mode) {
                case 'single':
                case 'list':
                case 'heatmap':
                case 'map':
                case 'table':
                    return true;
                default:
                    return false;
            }
        }

        function getFetchDuration(absoluteTime, chartModel) {
            return getFetchDurationFromConfig(
                absoluteTime,
                safeLookup(chartModel, 'sf_uiModel.chartconfig')
            );
        }

        function getFetchDurationFromConfig(absoluteTime, chartconfig) {
            const beginAt = chartconfig.absoluteStart;
            if (beginAt) {
                // FIXME : until we can actually specify fixed run times on the server, at least let
                // invokers ask for the raw value instead of the relative value
                if (!absoluteTime) {
                    let d = Date.now();
                    d = d - (d % 1000);
                    return beginAt - d;
                } else {
                    return beginAt;
                }
            }
            let durationToFetch = chartconfig.range;

            if (durationToFetch === null || durationToFetch === undefined) {
                durationToFetch = -1 * 60 * 1000;
            } else if (durationToFetch >= 0) {
                durationToFetch *= -1;
            }
            return durationToFetch;
        }

        function getSampleRate(chartModel) {
            if (
                featureEnabled('disableBrowserProtectionMode') &&
                isRenderThrottledChartMode(chartModel.sf_uiModel.chartMode)
            ) {
                return 0;
            }

            if (!chartModel.sf_uiModel.chartconfig.disableThrottle) {
                return SAMPLE_CONSTANTS.DEFAULT_SAMPLE_RATE;
            } else {
                if (chartModel.sf_uiModel.chartMode === 'heatmap') {
                    return SAMPLE_CONSTANTS.MAXIMUM_HEATMAP_SAMPLE_RATE;
                } else {
                    return SAMPLE_CONSTANTS.MAXIMUM_SAMPLE_RATE;
                }
            }
        }

        function getEndDuration(absoluteTime, chartModel) {
            return getEndDurationFromConfig(
                absoluteTime,
                safeLookup(chartModel, 'sf_uiModel.chartconfig')
            );
        }

        function getEndDurationFromConfig(absoluteTime, chartconfig) {
            let endAt = chartconfig.absoluteEnd;
            if (endAt) {
                // FIXME : until we can actually specify fixed run times on the server, at least let
                // invokers ask for the raw value instead of the relative value
                if (!absoluteTime) {
                    let d = Date.now();
                    d = d - (d % 1000);
                    return endAt - d;
                } else {
                    return endAt;
                }
            }
            endAt = chartconfig.rangeEnd;

            if (endAt >= 0) {
                endAt *= -1;
            }

            return endAt || 0;
        }

        function getJobRangeParameters(chartModel) {
            return getJobRangeParametersFromConfig(
                safeLookup(chartModel, 'sf_uiModel.chartconfig'),
                chartModel.sf_uiModel.chartMode
            );
        }

        function getJobRangeParametersFromConfig(chartconfig, chartMode) {
            let historyrange = getFetchDurationFromConfig(false, chartconfig);
            const endAt = getEndDurationFromConfig(false, chartconfig);
            const queryRange = historyrange - endAt;
            const resolutionOverride = chartconfig.resolution;
            const pointDensity = parseFloat(chartconfig.pointDensity || 1);
            const minResolution = parseInt(chartconfig.forcedResolution || 0);

            const res = getResolution(Math.abs(queryRange), minResolution, pointDensity);
            let fallbackResolutionMs = null;
            let targetResolution;

            if (isTimeSliceModeFromMode(chartMode)) {
                const isHeatmap = chartMode === 'heatmap';
                let backfillSliceCount = 2;

                targetResolution = Math.max(chartconfig.updateInterval || 10000, 1000);

                // backfill at least 12 intervals to show the sparkline, but if we're running in
                // ephemeral, backfill 100 intervals so that we are more likely to
                // extrapolate spotty data.

                // backfill extra data so we can extrapolate.
                // For heatmap, get 2 datapoints. Otherwise get atleast one
                // intersection of a cloudwatch interval at 5mnts
                if (!isHeatmap) {
                    backfillSliceCount += Math.min(Math.ceil((5 * 60000) / targetResolution), 12);
                }

                historyrange = endAt - backfillSliceCount * targetResolution;

                // if we're in timeslice mode, and "auto" was chosen, then add a fallback resolution of 5m to handle
                // intermittent or otherwise indeterminate resolutions
                if (!chartconfig.updateInterval) {
                    fallbackResolutionMs = 300000;
                }
            } else {
                // if a forced job resolution was specified, then make sure we ask for at LEAST that value regardless
                // of what was calculated using the chart dimensions
                // later, when we have a derived metric usecase(wherein the user may want finer data than automatically calculated),
                // we should make this value an explict override.
                targetResolution = res;
            }

            return {
                resolution: resolutionOverride || targetResolution,
                range: historyrange,
                endAt: endAt,
                fallbackResolutionMs: fallbackResolutionMs,
            };
        }

        function getEventQueryRange(config, mode) {
            let min;
            if (isAbsoluteTimeFromConfig(config)) {
                min = 1000;
            } else {
                min = 5000;
            }
            return Math.floor(
                Math.max(min, getJobRangeParametersFromConfig(config, mode).resolution)
            );
        }

        function timeRangeDifferent(chartModel, oldModel) {
            return (
                !modelsHaveSameRangeOptions(chartModel, oldModel) ||
                !modelsHaveSameAbsoluteOptions(chartModel, oldModel)
            );
        }

        function modelsHaveSameRangeOptions(modelA, modelB) {
            const pathToRange = 'sf_uiModel.chartconfig.range';
            const pathToRangeEnd = 'sf_uiModel.chartconfig.rangeEnd';

            return (
                deepCheckEquals(modelA, modelB, pathToRange) &&
                deepCheckEquals(modelA, modelB, pathToRangeEnd)
            );
        }

        function modelsHaveSameAbsoluteOptions(modelA, modelB) {
            const pathToAbsoluteStart = 'sf_uiModel.chartconfig.absoluteStart';
            const pathToAbsoluteEnd = 'sf_uiModel.chartconfig.absoluteEnd';

            return (
                deepCheckEquals(modelA, modelB, pathToAbsoluteStart) &&
                deepCheckEquals(modelA, modelB, pathToAbsoluteEnd)
            );
        }

        function deepCheckEquals(chartModelA, chartModelB, path) {
            const valueA = safeLookup(chartModelA, path);
            const valueB = safeLookup(chartModelB, path);

            return angular.equals(valueA, valueB);
        }

        function getMaxDecimalPlaces(chartModel) {
            const decimal = safeLookup(chartModel, 'sf_uiModel.chartconfig.maxDecimalPlaces');
            return decimal;
        }

        function isAbsoluteTime(chartModel) {
            return isAbsoluteTimeFromConfig(safeLookup(chartModel, 'sf_uiModel.chartconfig'));
        }

        function isAbsoluteTimeFromConfig(chartconfig) {
            return !!(chartconfig.absoluteStart && chartconfig.absoluteEnd);
        }

        function getLegendKeys(metadata, tsidToPlot) {
            const keys = {};
            angular.forEach(metadata, function (map, tsid) {
                const plot = tsidToPlot[tsid];
                if (plot && !plot.invisible) {
                    map.sf_key.forEach(function (key) {
                        keys[key] = true;
                    });
                    const threshold = getThresholdInfo(map);
                    if (threshold) {
                        keys[threshold > 0 ? 'highThreshold' : 'lowThreshold'] = true;
                    }
                }
            });
            return Object.keys(keys);
        }

        const allowedResolutions = [
            1000,
            2000,
            3000,
            4000,
            5000,
            10000,
            20000,
            30000,
            60000,
            2 * 60000,
            3 * 60000,
            4 * 60000,
            5 * 60000,
            15 * 60000,
            30 * 60000,
            60 * 60000,
            2 * 60 * 60000,
            3 * 60 * 60000,
            4 * 60 * 60000,
            5 * 60 * 60000,
            6 * 60 * 60000,
            12 * 60 * 60000,
            24 * 60 * 60000,
            2 * 24 * 60 * 60000,
            3 * 24 * 60 * 60000,
            4 * 24 * 60 * 60000,
            5 * 24 * 60 * 60000,
            6 * 24 * 60 * 60000,
            7 * 24 * 60 * 60000,
        ];

        function getResolution(duration, minimum, pointDensity) {
            const roundedDuration = duration - (duration % 1000);
            // aim for 60 points on the screen in standard density (1)
            const targetResolution = Math.max(roundedDuration / 60 / pointDensity, minimum);
            let closestResolutionIndex = 0;
            let minDifference = Math.abs(allowedResolutions[0] - targetResolution);
            //find the nearest allowed resolution.
            for (let resIdx = 0; resIdx < allowedResolutions.length; resIdx++) {
                if (minimum && allowedResolutions[resIdx] < minimum) {
                    //skip any resolution that is below the minimum cnostraint
                    closestResolutionIndex = resIdx;
                    continue;
                }
                if (Math.abs(allowedResolutions[resIdx] - targetResolution) < minDifference) {
                    minDifference = Math.abs(allowedResolutions[resIdx] - targetResolution);
                    closestResolutionIndex = resIdx;
                }
            }
            return allowedResolutions[closestResolutionIndex];
        }

        function mergeJobKeysAndColumnConfiguration(jobKeys, columnConfigs) {
            const keyList = [];
            const jobKeyMap = {};
            jobKeys.forEach(function (val) {
                jobKeyMap[val] = true;
            });
            //in order, add the enabled columns that were returned in this job.
            angular.forEach(columnConfigs, function (columnConfig) {
                if (jobKeyMap[columnConfig.property]) {
                    // if the job has this property, AND the property is enabled, add it to the keylist
                    // but in all cases, keep track that we checked this particular property
                    if (columnConfig.enabled) {
                        keyList.push(columnConfig.property);
                    }
                    jobKeyMap[columnConfig.property] = false;
                }
            });
            //then add whatever remains
            angular.forEach(jobKeyMap, function (notAdded, key) {
                if (notAdded) {
                    keyList.push(key);
                }
            });
            return keyList;
        }

        function getRenderScore(viztype, stacked) {
            if (viztype === 'heatmap') {
                return 2;
            } else if (viztype === 'area') {
                return 2;
            } else if (viztype === 'column') {
                return stacked ? 2 : 20;
            } else {
                //line
                return 1;
            }
        }

        function getNonTransientMetricPlots(plots) {
            return plots.filter((p) => {
                return !p.transient && (p.type === 'plot' || p.type === 'ratio');
            });
        }

        function calculateRenderScoreV2(uimodel, metaDataMap) {
            let score = 1;
            const baseRenderType = uimodel.chartType;
            const labelToVizType = {};
            angular.forEach(uimodel.allPlots, function (plot) {
                if (!plot.invisible) {
                    labelToVizType[plot._originalLabel] = plot.visualization;
                }
            });
            angular.forEach(metaDataMap, function (mdata) {
                score += getRenderScore(
                    labelToVizType[mdata.sf_label] || baseRenderType || 'line',
                    uimodel.chartconfig.stackedChart
                );
            });
            return score;
        }

        function calculateRenderScore(uimodel, plotKeyToInfoMap, plotCap) {
            if (!plotKeyToInfoMap) {
                return null;
            }

            const plots = getNonTransientMetricPlots(uimodel.allPlots);
            const baseRenderType = uimodel.chartType;
            let score = 1;
            if (Object.keys(plotKeyToInfoMap).length !== plots.length) {
                return null;
            }
            angular.forEach(plots, function (plot) {
                if (!plot.invisible) {
                    score +=
                        Math.min(
                            plotKeyToInfoMap[plot.uniqueKey].timeSeriesPrePublish || 0,
                            plotCap
                        ) *
                        getRenderScore(
                            plot.visualization || baseRenderType || 'line',
                            uimodel.chartconfig.stackedChart
                        );
                }
            });
            return score;
        }

        function getRenderThrottleStats(uimodel, plotKeyToInfoMap, plotCap, ratio) {
            if (!plotKeyToInfoMap) {
                return null;
            }

            const plots = getNonTransientMetricPlots(uimodel.allPlots);
            let visibleCount = 0;
            let unsampledCount = 0;
            if (Object.keys(plotKeyToInfoMap).length !== plots.length) {
                return null;
            }
            angular.forEach(plots, function (plot) {
                if (!plot.invisible) {
                    unsampledCount += plotKeyToInfoMap[plot.uniqueKey].timeSeriesPrePublish || 0;
                    visibleCount += Math.min(
                        plotKeyToInfoMap[plot.uniqueKey].timeSeriesPrePublish || 0,
                        plotCap
                    );
                }
            });
            return {
                rendered: Math.ceil(visibleCount * ratio),
                total: unsampledCount,
            };
        }

        function isRenderThrottledChartMode(mode) {
            return mode === 'graph' || mode === 'list' || mode === 'single' || mode === 'heatmap';
        }

        const FIRE_TYPE = 'FIRE';
        const CLEAR_TYPE = 'CLEAR';

        function getThresholdType(metaData, incidentInfo, isDetectorNative) {
            if (metaData) {
                const uiConfig = getUiConfig(metaData, incidentInfo, isDetectorNative);
                const state = uiConfig.sfui_state;

                if (state) {
                    if (state.toLowerCase().includes('fire')) {
                        return FIRE_TYPE;
                    } else if (state.toLowerCase().includes('clear')) {
                        return CLEAR_TYPE;
                    }
                }
            }

            return null;
        }

        function getUiConfig(metaData, incidentInfo, isDetectorNative) {
            if (!incidentInfo && !isDetectorNative) {
                return metaData;
            }

            if (typeof metaData.sfui_config === 'string') {
                metaData.sfui_config = angular.fromJson(metaData.sfui_config);
            }

            if (metaData.sfui_config) {
                if (isDetectorNative) {
                    // if in detector chart display, give the first config
                    // TODO jhan get the correct config
                    const keys = Object.keys(metaData.sfui_config);
                    if (keys.length === 1) {
                        return metaData.sfui_config[keys[0]];
                    }
                } else if (metaData.sfui_config[incidentInfo.conditionIdentifier]) {
                    return metaData.sfui_config[incidentInfo.conditionIdentifier];
                }
            }
            return metaData;
        }

        function getThresholdInfo(metaData, incidentInfo, isDetectorNative) {
            if (metaData) {
                //2 = within, 1 = out of bounds
                const uiConfig = getUiConfig(metaData, incidentInfo, isDetectorNative);
                if (uiConfig.sfui_streamType === 'threshold') {
                    if (uiConfig.sfui_state === 'fire') {
                        const triggerValue = uiConfig.sfui_trigger === 'inside' ? 2 : 1;
                        const triggerDirection = uiConfig.sfui_orientation === 'above' ? 1 : -1;
                        return triggerValue * triggerDirection;
                    }
                }
            }
            return null;
        }

        function getThresholdVisibility(metaData) {
            return getThresholdType(metaData) !== CLEAR_TYPE;
        }

        function getDefaultVariables(filterAlias) {
            let defaults = [];
            if (filterAlias) {
                defaults = filterAlias.map(function (v) {
                    return {
                        property: v.property,
                        propertyValue: v.value,
                        alias: v.alias,
                        required: v.required,
                        replaceOnly: v.replaceOnly,
                        applyIfExists: v.applyIfExists,
                    };
                });
            }
            return defaults;
        }

        function setDefaultVariables(filterAlias, variables) {
            const defaultVariables = getDefaultVariables(filterAlias).filter(function (f) {
                return f.propertyValue;
            });
            variables.splice.apply(variables, [0, variables.length].concat(defaultVariables));
        }

        function updateVariables(variables, variablesFromScope, filterAlias) {
            //appears to convert filter alias definitions with appropriate default values while applying
            //url overrides to said filters, then merges the definition from the url with the variable metadata(required, etc)

            const currentVariables = angular.copy(variablesFromScope);
            if (!variables || !variables.length) {
                setDefaultVariables(filterAlias, variablesFromScope);
            } else {
                const defaults = getDefaultVariables(filterAlias);
                // variable is "alias=property:value"
                const updatedVariables = variables
                    .map(function (v) {
                        const variable = v.split('=');
                        const variableName = variable[0] || '';
                        let propertyAndValue = variable[1] || '';
                        const applyIfExists = propertyAndValue.match(/^~/) !== null;
                        propertyAndValue = propertyAndValue.replace(/^~/, '');
                        const property = propertyAndValue.split(':')[0] || '';
                        const value = sourceFilterService.unflattenPropertyValue(
                            propertyAndValue.substr(property.length + 1)
                        );
                        return {
                            property: property,
                            propertyValue: value,
                            applyIfExists: applyIfExists,
                            alias: variableName,
                        };
                    })
                    .filter(function (v) {
                        // ignore overrides that try to clear out a required variable
                        if (
                            v.propertyValue === undefined ||
                            v.propertyValue === '' ||
                            v.propertyValue === null
                        ) {
                            return !defaults.some(function (d) {
                                return d.required && d.alias === v.alias;
                            });
                        } else {
                            return v.propertyValue;
                        }
                    });
                defaults.forEach(function (v, i) {
                    updatedVariables.forEach(function (uv) {
                        if (uv.property === v.property) {
                            defaults[i].propertyValue = uv.propertyValue;
                        }
                    });
                });
                const defaultVariables = defaults.filter(function (f) {
                    return f.propertyValue;
                });
                variablesFromScope.splice.apply(
                    variablesFromScope,
                    [0, variablesFromScope.length].concat(defaultVariables)
                );
            }
            return currentVariables !== variablesFromScope;
        }

        function updateSources(model, sources) {
            const sourcesForETS = angular.copy(sources);
            sourcesForETS.forEach(function (s) {
                s.optional = true;
            });
            angular.forEach(model.sf_uiModel.allPlots, function (plot) {
                if (!plot.transient && !plotUtils.isAliasedRegExStyle(plot)) {
                    plot.queryItems = plot.queryItems
                        .filter(function (q) {
                            return !sources.some(function (filter) {
                                const notFilter = filter.NOT ? true : false;
                                const notQuery = q.NOT ? true : false;
                                return filter.property === q.property && notFilter === notQuery;
                            });
                        })
                        .concat(plot.type === 'event' ? sourcesForETS : sources);
                }
            });
            programTextUtils.refreshProgramText(model);
        }

        function updateGlobalTimeRange(model, timeRange) {
            if (timeRange === null) {
                return;
            }
            model.sf_uiModel.chartconfig.absoluteStart = timeRange.absoluteStart;
            model.sf_uiModel.chartconfig.absoluteEnd = timeRange.absoluteEnd;
            model.sf_uiModel.chartconfig.range = timeRange.range;
            model.sf_uiModel.chartconfig.rangeEnd = timeRange.rangeEnd;
        }

        function applyPointDensity(model, density) {
            if (!model.sf_uiModel.chartconfig) {
                model.sf_uiModel.chartconfig = {};
            }
            if (density) {
                model.sf_uiModel.chartconfig.pointDensity = density;
            } else {
                model.sf_uiModel.chartconfig.pointDensity = '';
            }
        }

        function getRollupMessage(rollupApplied, commonRollupType) {
            if (
                (angular.isUndefined(rollupApplied) && commonRollupType !== '') ||
                angular.isUndefined(commonRollupType)
            ) {
                return 'determining rollup...';
            }

            // Check this first since commonRollupType won't be set when none of the plots have a rollup applied
            if (rollupApplied === false) {
                return 'no rollup applied';
            }

            if (commonRollupType === '') {
                // Rollup information is known to be unavailable
                return commonRollupType;
            }

            if (commonRollupType) {
                return `${commonRollupType.displayName.toLowerCase()} rollup applied to source data`;
            }

            return 'multiple rollups applied to source data';
        }

        function getRollupAcrossPlots(allPlots, plotKeyToInfoMap) {
            if (!(plotKeyToInfoMap && allPlots && allPlots.length)) {
                return;
            }

            let firstRollupAssigned = false;
            // Default to undefined since this is not yet determined
            let rollupApplied;
            // Default to empty string until we have determined the type or that there is missing information
            let rollupAcrossPlots = '';

            for (const plot of allPlots) {
                const plotInfo = plotKeyToInfoMap[plot.uniqueKey];
                if (!plot.transient && plotInfo) {
                    if (
                        !plot.invisible &&
                        ((angular.isUndefined(plotInfo.rollupApplied) &&
                            plotInfo.commonRollupType !== '') ||
                            angular.isUndefined(plotInfo.commonRollupType))
                    ) {
                        // Missing information
                        return 'determining rollup...';
                    }

                    if (angular.isUndefined(rollupApplied)) {
                        // Initialize
                        rollupApplied = plotInfo.rollupApplied;
                    }

                    if (plotInfo.rollupApplied) {
                        rollupApplied = true;
                        if (!firstRollupAssigned) {
                            // Initialize
                            rollupAcrossPlots = plotInfo.commonRollupType;
                            firstRollupAssigned = true;
                        } else if (rollupAcrossPlots !== plotInfo.commonRollupType) {
                            // There are different rollup types across plots
                            rollupAcrossPlots = null;
                            break;
                        }
                    }
                }
            }

            return getRollupMessage(rollupApplied, rollupAcrossPlots);
        }

        function jobMessagesReceivedV2(msgs, chartModel) {
            const sampleRate = getSampleRate(chartModel);
            const seenLines = {};
            const plotKeyToInfoMap = chartbuilderUtil.processJobMessages(
                chartModel.sf_uiModel.allPlots,
                msgs
            );
            const jobSummary = {
                plotKeyToInfoMap,
                receivedMaxDelay: 0,
                jobFetchCaps: {
                    isSignalFlowMode: true,
                    numInaccuratePlots: 0,
                    inaccuratePlotLabels: [],
                    inaccuratePlotKeys: {},
                    fetchCap: -1,
                },
                primaryJobResolution: 0,
                dataThrottled: false,
                throttleCount: 0,
                passThruCount: 0,
                totalTimeseriesCount: 0,
                jobTimeshiftCoarsened: false,
                timeshiftOffset: {
                    newOffset: '',
                    oldOffset: '',
                },
                archivedMetrics: null,
            };

            msgs.forEach(function (message) {
                if (message.messageCode === 'ID_NUM_TIMESERIES') {
                    // Input time series, e.g., before filters are applied
                    const { totalTimeseriesCount, passThruCount, throttleCount } = jobSummary;

                    // When we know the total number of timeseries and the total count is already reached, we ignore further messages
                    if (
                        totalTimeseriesCount > 0 &&
                        passThruCount + throttleCount >= totalTimeseriesCount
                    ) {
                        return;
                    }
                    const tsCount = message.numInputTimeSeries;
                    if (sampleRate && tsCount > sampleRate) {
                        jobSummary.throttleCount += tsCount - sampleRate;
                        jobSummary.passThruCount += sampleRate;
                        jobSummary.dataThrottled = true;
                    } else {
                        jobSummary.passThruCount += tsCount;
                    }
                } else if (message.messageCode === 'FETCH_NUM_TIMESERIES') {
                    // Output time series, e.g., after filters are applied
                    jobSummary.totalTimeseriesCount = message.numInputTimeSeries;
                    if (message.numInputTimeSeries === 0) {
                        // FETCH_NUM_ROLLUPS won't be sent when there is no output data. Get the rollup message text here.
                        jobSummary.chartRollupMessage = getRollupAcrossPlots(
                            chartModel.sf_uiModel.allPlots,
                            plotKeyToInfoMap
                        );
                    }
                } else if (message.messageCode === 'FIND_LIMITED_RESULT_SET') {
                    jobSummary.jobFetchCaps.numInaccuratePlots++;
                    const lineNo = message.blockContext.line;
                    if (!seenLines[lineNo]) {
                        jobSummary.jobFetchCaps.inaccuratePlotLabels.push(lineNo);
                        seenLines[lineNo] = true;
                    }
                    jobSummary.jobFetchCaps.fetchCap = message.contents.limitSize;
                } else if (message.messageCode === 'JOB_RUNNING_RESOLUTION') {
                    const res = message.contents.resolutionMs;
                    jobSummary.primaryJobResolution = res;
                } else if (message.messageCode === 'JOB_INITIAL_MAX_DELAY') {
                    jobSummary.receivedMaxDelay = message.contents.maxDelayMs;
                    return true;
                } else if (message.messageCode === 'FETCH_NUM_ROLLUPS') {
                    jobSummary.chartRollupMessage = getRollupAcrossPlots(
                        chartModel.sf_uiModel.allPlots,
                        plotKeyToInfoMap
                    );
                } else if (message.messageCode === 'WINDOW_MISALIGNED_RESOLUTION') {
                    jobSummary.misalignedResolution = true;
                } else if (message.messageCode === 'JOB_TIMESHIFT_COARSENED') {
                    jobSummary.jobTimeshiftCoarsened = true;
                    jobSummary.timeshiftOffset.oldOffset = timepickerUtils.msToDisplayValue(
                        message.contents.oldOffsetMs
                    );
                    jobSummary.timeshiftOffset.newOffset = timepickerUtils.msToDisplayValue(
                        message.contents.newOffsetMs
                    );
                } else if (message.messageCode === 'FIND_ARCHIVED_METRICS') {
                    jobSummary.archivedMetrics = message.contents.archivedMetrics;
                }
            });

            return jobSummary;
        }

        function jobMessagesReceivedV1(msgs, chartModel) {
            const jobSummary = {};

            const plotKeyToInfoMap = chartbuilderUtil.processJobMessages(
                chartModel.sf_uiModel.allPlots,
                msgs
            );
            const sampleRate = chartModel.sf_uiModel.chartconfig.disableThrottle
                ? SAMPLE_CONSTANTS.MAXIMUM_SAMPLE_RATE
                : SAMPLE_CONSTANTS.DEFAULT_SAMPLE_RATE;
            jobSummary.plotKeyToInfoMap = plotKeyToInfoMap;
            jobSummary.receivedMaxDelay = 0;
            msgs.some(function (msg) {
                if (msg.messageCode === 'JOB_INITIAL_MAX_DELAY') {
                    jobSummary.receivedMaxDelay = msg.contents.maxDelayMs;
                    return true;
                }
            });
            let dataThrottled = false;
            let throttleCount = 0;
            let passThruCount = 0;
            const jobTimeshiftCoarsened = false;
            const timeshiftOffset = {
                newOffset: '',
                oldOffset: '',
            };
            //TODO : update chartbuilder to also do this, but once we can get it right across visibility toggles.
            // this is only okay here because dashboard displays of this component only run view signalflow
            const uiModelPlotsByKey = {};
            const fetchCapInfo = {
                numInaccuratePlots: 0,
                inaccuratePlotLabels: [],
                inaccuratePlotKeys: {},
                fetchCap: -1,
            };
            chartModel.sf_uiModel.allPlots.forEach(function (plot) {
                uiModelPlotsByKey[plot.uniqueKey] = plot;
            });
            angular.forEach(plotKeyToInfoMap, function (plot, plotKey) {
                //Disable throttling if a select block is being used due to how SAMPLE works.
                //we didnt actually throttle this output if top or bottom was present

                if (plot.mtsCap) {
                    fetchCapInfo.fetchCap = plot.mtsCap;
                }

                if (uiModelPlotsByKey[plotKey].invisible) {
                    return;
                }
                const hasTopOrBottom = chartModel.sf_uiModel.allPlots.some(function (plotModel) {
                    if (plotModel.uniqueKey !== parseInt(plotKey, 10)) return;
                    return (plotModel.dataManipulations || []).some(function (manip) {
                        return manip.fn.type === 'TOPN' || manip.fn.type === 'BOTTOMN';
                    });
                });

                if (
                    plot.timeSeriesPrePublish > sampleRate &&
                    !hasTopOrBottom &&
                    !uiModelPlotsByKey[plotKey].invisible
                ) {
                    dataThrottled = true;
                    throttleCount += plot.timeSeriesPrePublish - sampleRate;
                    passThruCount += sampleRate;
                } else {
                    passThruCount += plot.timeSeriesPrePublish;
                }

                if (plot.visible && plot.mtsCapped) {
                    fetchCapInfo.inaccuratePlotKeys[plotKey] = true;
                    fetchCapInfo.inaccuratePlotLabels.push(
                        plotUtils.getLetterFromUniqueKey(plotKey)
                    );
                    fetchCapInfo.numInaccuratePlots++;
                }
            });

            jobSummary.dataThrottled = dataThrottled;
            jobSummary.throttleCount = throttleCount;
            jobSummary.passThruCount = passThruCount;
            jobSummary.jobTimeshiftCoarsened = jobTimeshiftCoarsened;
            jobSummary.timeshiftOffset = timeshiftOffset;

            jobSummary.primaryJobResolution = null;
            msgs.forEach(function (msg) {
                if (msg.messageCode === 'JOB_RUNNING_RESOLUTION') {
                    const res = msg.contents.resolutionMs;
                    jobSummary.primaryJobResolution = res;
                } else if (
                    msg.messageCode === 'FETCH_NUM_ROLLUPS' ||
                    (msg.messageCode === 'FETCH_NUM_TIMESERIES' && msg.numInputTimeSeries === 0)
                ) {
                    jobSummary.chartRollupMessage = getRollupAcrossPlots(
                        chartModel.sf_uiModel.allPlots,
                        plotKeyToInfoMap
                    );
                } else if (msg.messageCode === 'WINDOW_MISALIGNED_RESOLUTION') {
                    jobSummary.misalignedResolution = true;
                } else if (msg.messageCode === 'JOB_TIMESHIFT_COARSENED') {
                    jobSummary.jobTimeshiftCoarsened = true;
                    jobSummary.timeshiftOffset.newOffset = timepickerUtils.msToDisplayValue(
                        msg.contents.newOffsetMs
                    );
                    jobSummary.timeshiftOffset.oldOffset = timepickerUtils.msToDisplayValue(
                        msg.contents.oldOffsetMs
                    );
                } else if (msg.messageCode === 'FIND_ARCHIVED_METRICS') {
                    jobSummary.archivedMetrics = msg.contents.archivedMetrics;
                }
            });

            jobSummary.jobFetchCaps = fetchCapInfo;
            return jobSummary;
        }

        function jobMessagesReceived(messages, chartModel) {
            if (!chartModel.$isOriginallyV2) {
                return jobMessagesReceivedV1(messages, chartModel);
            } else {
                return jobMessagesReceivedV2(messages, chartModel);
            }
        }

        function applyUrlStateToModel(model, filterAlias) {
            const timepicker = timepickerUtils.getChartConfigURLTimeParameters();
            let sourceOverride = urlOverridesService.getSourceOverride();
            let variablesOverride = dashboardVariablesService.getVariablesOverride();
            let pointDensityOverride = urlOverridesService.getPointDensity();

            if (!sourceOverride) sourceOverride = [];
            if (!variablesOverride) variablesOverride = [];
            if (!pointDensityOverride) pointDensityOverride = null;

            const sourceFilters = sourceFilterService.getSourceFilters(sourceOverride);

            updateVariables(
                dashboardVariablesService.getVariablesOverride(),
                variablesOverride,
                filterAlias
            );

            chartUtils.applyFiltersToPlots(variablesOverride, sourceFilters, model, false);

            if (sourceFilters && sourceFilters.length) {
                updateSources(model, sourceFilters);
            }

            if (timepicker) {
                updateGlobalTimeRange(model, timepicker);
            }

            applyPointDensity(model, pointDensityOverride);
        }

        function createConformingModelFromV2(chart) {
            const newTransformedModel = {
                sf_chart: chart.name,
                sf_description: chart.description,
                sf_chartIndex: chart.sf_chartIndex,
                sf_id: chart.id,
                sf_uiModel: {
                    allPlots: [],
                    chartMode: 'graph',
                    chartType: 'line',
                    revisionNumber: 1,
                },
                sf_viewProgramText: chart.programText,
                sf_flowVersion: 2,
                sf_jobMaxDelay: 0,
            };

            // for whatever reason it appears that options can be null, handle this case so we
            // dont throw and show an empty dashboard.
            if (chart.options) {
                const uiModelExtension = visualizationOptionsToUIModel(chart.options);
                angular.extend(newTransformedModel.sf_uiModel, uiModelExtension);
            }

            //add a y axis if none was provided
            if (!newTransformedModel.sf_uiModel.chartconfig) {
                newTransformedModel.sf_uiModel.chartconfig = {};
            }

            if (!newTransformedModel.sf_uiModel.chartconfig.yAxisConfigurations) {
                newTransformedModel.sf_uiModel.chartconfig.yAxisConfigurations = [];
            }

            addUnresolvedYAxes(newTransformedModel.sf_uiModel);

            return newTransformedModel;
        }

        function findPlot(plots, labelDef) {
            return plots.find(function (plot) {
                return plot._originalLabel && plot._originalLabel === labelDef.label;
            });
        }

        function getLabelInformation(programText, programArgs) {
            const isDashboardTimeWindowEnabled = featureEnabled('dashboardTimeWindow');
            return $http({
                method: 'POST',
                url: API_URL + '/v2/signalflow/_/getProgramInfo?ephemeral=true',
                data: isDashboardTimeWindowEnabled
                    ? {
                          programText,
                          programArgs,
                      }
                    : programText,
                headers: {
                    'Content-Type': isDashboardTimeWindowEnabled
                        ? 'application/json'
                        : 'text/plain',
                },
            }).then(
                function (resp) {
                    return resp.data;
                },
                function (resp) {
                    $log.error(
                        'Failed to parse program text into publishes, so styling cannot be done!'
                    );
                    return $q.reject(resp);
                }
            );
        }

        function processKnownLabels(programInfo, v1Model, orphanedPlots) {
            //takes known publish labels and reorders/copies existing v1 model plots that correspond to provided publish
            //labels, as well as inserts dummy plot placeholders for anonymous publishes.  this ensures plot order matches
            //publish order, which allows for consistent stacking.
            const knownLabels = programInfo.streamPublishInfo
                .map(function (streamInfo) {
                    streamInfo.type = 'plot';
                    return streamInfo;
                })
                .concat(
                    programInfo.eventLabels.map(function (l) {
                        return { type: 'event', label: l, disabled: false, metricTrailer: null };
                    })
                );
            const labelToIndex = {};
            knownLabels.forEach(function (val, idx) {
                if (val.label) {
                    if (!labelToIndex[val.label]) {
                        labelToIndex[val.label] = [];
                    }
                    labelToIndex[val.label].push(idx);
                }
            });
            const existingLabels = {};
            const existingSuffixes = {};
            const existingPlots = [];
            let maxIdx = 0;

            v1Model.sf_uiModel.allPlots.forEach(function (plot) {
                if (maxIdx < plot.uniqueKey) {
                    maxIdx = plot.uniqueKey;
                }
                if (angular.isDefined(labelToIndex[plot._originalLabel])) {
                    existingLabels[plot._originalLabel] = plot;
                    existingSuffixes[plot._originalSuffix] = plot;
                    existingPlots.push(plot);
                } else if (!plot._isOrderPlaceHolder && !plot.transient) {
                    orphanedPlots.push(plot);
                }
            });
            maxIdx++;

            function keyInUse(key) {
                return !!existingKeys[key];
            }

            const existingKeys = {};
            const revisedPlots = [];
            knownLabels.forEach(function (l) {
                //create plots in returned publish order.
                let ep = findPlot(existingPlots, l);
                let op = findPlot(orphanedPlots, l);
                if (ep) {
                    ep = angular.copy(ep);

                    if (keyInUse(ep.uniqueKey)) {
                        ep.uniqueKey = maxIdx++;
                    }
                    existingKeys[ep.uniqueKey] = true;

                    ep._originalLabel = l.label;
                    ep.name = ep.name || '';
                    ep._originalSuffix = l.metricTrailer;
                    ep._isOrderPlaceHolder = false;
                    revisedPlots.push(ep);
                } else if (op && l.label) {
                    const originalIndex = orphanedPlots.indexOf(op);
                    op = angular.copy(op);

                    if (keyInUse(op.uniqueKey)) {
                        op.uniqueKey = maxIdx++;
                    }
                    existingKeys[op.uniqueKey] = true;

                    op.uniqueKey = maxIdx++;
                    op._originalLabel = l.label;
                    op._originalSuffix = l.metricTrailer;
                    op.name = op.name || '';
                    op._isOrderPlaceHolder = false;
                    orphanedPlots.splice(originalIndex, 1);
                    revisedPlots.push(op);
                } else if (l.metricTrailer && !l.label) {
                    revisedPlots.push({
                        _isOrderPlaceHolder: true,
                        _originalLabel: l.label,
                        _originalSuffix: l.metricTrailer,
                        uniqueKey: maxIdx++,
                        name: l.label || '',
                        yAxisIndex: 0,
                        seriesData: {},
                        configuration: {
                            colorOverride: null,
                            visualization: null,
                        },
                        transient: false,
                        type: 'plot',
                        invisible: false,
                        queryItems: [],
                        dataManipulations: [],
                    });
                } else {
                    revisedPlots.push({
                        _originalLabel: l.label,
                        _originalSuffix: l.metricTrailer,
                        uniqueKey: maxIdx++,
                        name: l.label || '',
                        yAxisIndex: 0,
                        seriesData: {},
                        configuration: {
                            colorOverride: null,
                            visualization: null,
                        },
                        transient: false,
                        type: l.type,
                        invisible: false,
                        queryItems: [],
                        dataManipulations: [],
                    });
                }
            });
            v1Model.sf_uiModel.allPlots = revisedPlots;
            let maxKey = 0;
            revisedPlots.forEach((p) => {
                if (p.uniqueKey > maxKey) {
                    maxKey = p.uniqueKey;
                }
            });
            maxKey++;

            v1Model.sf_uiModel.currentUniqueKey = maxKey;
        }

        function getHighestCardinalityDimension(dimensionKeysArray, tsIdToMetadataMap) {
            const metadataList = _.values(tsIdToMetadataMap);
            return _.maxBy(dimensionKeysArray, function (dimensionKey) {
                return _.uniq(_.map(metadataList, dimensionKey)).length;
            });
        }

        const aliasedName = {
            sf_metric: 'Plot name',
            sf_originatingMetric: 'metric (sf_metric)',
        };

        function getAliasedName(prop) {
            if (aliasedName[prop]) {
                return aliasedName[prop];
            }

            return prop;
        }

        function getDisallowedKeys() {
            return ['jobId', 'computationId'];
        }

        function getSyntheticPlot() {
            return {
                dataManipulations: [],
                invisible: false,
                metricDefinition: {},
                name: '',
                queryItems: [],
                seriesData: {
                    metric: '',
                    regExStyle: null,
                },
                transient: false,
                type: 'plot',
                synthetic: true,
                uniqueKey: -1,
                yAxisIndex: 0,
                configuration: {
                    rollupPolicy: null,
                },
            };
        }

        function getPlotObject(metadata, chartObject, isOriginallyV2) {
            if (!metadata) {
                return null;
            }

            if (isOriginallyV2 === undefined) {
                // if no original model state is provided, then make our best determination by duck typing
                isOriginallyV2 =
                    chartObject.sf_modelVersion === 2 || chartObject.sf_flowVersion === 2;
            }

            if (isOriginallyV2) {
                return getPlotObjectFromV2Chart(metadata, chartObject);
            } else if (metadata.sf_streamLabel) {
                return getPlotObjectFromV1Chart(metadata, chartObject);
            } else {
                return getSyntheticPlot();
            }
        }

        // for v2 charts, take the labels verbatim as the keys
        function getPlotObjectFromV2Chart(metadata, chartObject) {
            const allPlots = chartObject.sf_uiModel.allPlots;
            const streamLabel = metadata.sf_streamLabel;
            const plotV2 = allPlots.find((plot) => {
                return (
                    metadata.sf_metric.endsWith(plot._originalSuffix) ||
                    plot._originalLabel === streamLabel
                );
            });

            return plotV2 || getSyntheticPlot();
        }

        // for v1 charts, expect that the labels be numbers matching plot uniqueKeys
        function getPlotObjectFromV1Chart(metadata, chartObject) {
            const streamLabel = metadata.sf_streamLabel;
            const allPlots = chartObject.sf_uiModel.allPlots;
            const uniqueKey = plotUtils.getUniqueKeyFromLetter(streamLabel);
            const plotObject = allPlots.find((plot) => plot.uniqueKey === uniqueKey);

            if (plotObject) {
                return plotObject;
            } else {
                return getInvisibleSynthPlotAsFallback(chartObject, streamLabel);
            }
        }

        function getInvisibleSynthPlotAsFallback(chartObject) {
            $log.warn('Could not find matching plot, defaulting to invisible synthetic!');

            const invisibleSyntheticPlot = getSyntheticPlot();

            if (!chartObject.showAllSynthetic) {
                invisibleSyntheticPlot.invisible = true;
            }

            return invisibleSyntheticPlot;
        }

        function getSeriesMetadataFromPlot(metaData, plotObj) {
            const syntheticName = '';
            let src = metaData.sf_source;
            let metric = metaData.sf_streamLabel || '';

            if (plotObj && !metaData.sf_detectorDerived) {
                metric = plotObj.name || plotObj._originalLabel;
            } else {
                metric = metaData.sf_metric;
                if (angular.isUndefined(metric)) {
                    metric = 'Unknown Metric';
                }
            }
            if (syntheticName && syntheticName === src) {
                src = ''; // if defaultSource was applied, we dont really know what to show....
            }

            return {
                source: src,
                metric: metric,
                plot: plotObj,
                sf_key: metaData.sf_key,
                raw: metaData,
            };
        }

        function getSeriesMetadata(chart, metaData, isOriginallyV2) {
            if (!metaData) {
                metaData = {
                    sf_key: ['sf_source'],
                    sf_source: 'Unknown Source',
                    sf_metric: 'Unknown Metric',
                };
            }

            const plotObj = getPlotObject(metaData, chart, isOriginallyV2);

            return getSeriesMetadataFromPlot(metaData, plotObj);
        }

        function filterDimensionKeys(keys, values, keysToIgnore) {
            return keys.filter((key) => {
                const value = values[key];
                return (
                    keysToIgnore.indexOf(key) === -1 &&
                    value &&
                    value.indexOf('_SF_PLOT_KEY') !== 0 &&
                    value.indexOf('_SF_COMP_') !== 0
                );
            });
        }

        function resolveDimensions(
            metadata,
            chartModel,
            skipOriginalMetricInAutoMode,
            isOriginallyV2,
            seriesMetadata,
            columnConfig,
            includeDisabledCols,
            skipOriginalMetric
        ) {
            // temporarily cram all the keys into the name
            if (!metadata || plot === null) {
                return null;
            }

            const plot = getPlotObject(metadata, chartModel);
            if (isOriginallyV2 === undefined) {
                isOriginallyV2 =
                    chartModel.sf_modelVersion === 2 || chartModel.sf_flowVersion === 2;
            }
            const names = seriesMetadata || getSeriesMetadata(chartModel, metadata, isOriginallyV2);
            const colConfig =
                columnConfig ||
                safeLookup(chartModel, 'sf_uiModel.chartconfig.legendColumnConfiguration') ||
                [];
            const keys = mergeJobKeysAndColumnConfiguration(names.sf_key, colConfig);
            const systemDimensionKeys = getReservedDimensionKeys();

            const ignoreKeys = ['sf_metric'];

            // ignore originatingMetric if we're in "automatic" mode
            if (skipOriginalMetric || (!colConfig.length && !skipOriginalMetricInAutoMode)) {
                ignoreKeys.push('sf_originatingMetric');
            }

            const resolvedDimensions = {};

            const enabledKeyMap = {};

            const filteredKeys = filterDimensionKeys(
                keys,
                names.raw,
                ignoreKeys.concat(systemDimensionKeys)
            );

            for (const index in filteredKeys) {
                const key = filteredKeys[index];
                enabledKeyMap[key] = true;
                const value = names.raw[key];
                // If we're including disabled columns in resolvedDimensions, represent
                // the dimension value with an object that has a 'disabled' prop
                // indicating whether it was one of the disabled cols or not, for usage
                // downstream.
                resolvedDimensions[key] = includeDisabledCols ? { disabled: false, value } : value;
            }

            if (includeDisabledCols) {
                // Find the dimensions that are disabled in the chart column config
                // (if any) and add them to resolvedDimensions.
                filterDimensionKeys(
                    names.sf_key,
                    names.raw,
                    ignoreKeys.concat(systemDimensionKeys)
                ).forEach((key) => {
                    if (!enabledKeyMap[key]) {
                        const value = names.raw[key];
                        resolvedDimensions[key] = { disabled: true, value };
                    }
                });
            }

            return resolvedDimensions;
        }

        function resolveSeriesName(
            metadata,
            chartModel,
            skipMetric,
            skipOriginalMetricInAutoMode,
            isOriginallyV2,
            skipOriginalMetric
        ) {
            const seriesMetadata = getSeriesMetadata(chartModel, metadata, isOriginallyV2);
            const columnConfig =
                safeLookup(chartModel, 'sf_uiModel.chartconfig.legendColumnConfiguration') || [];
            const keys = mergeJobKeysAndColumnConfiguration(seriesMetadata.sf_key, columnConfig);
            let plotName = seriesMetadata.metric;

            // if we've explicitly asked to skip the metric, or custom column configuration lacks it,
            // then kill off the value
            if (skipMetric || (columnConfig && keys.indexOf('sf_metric') === -1)) {
                plotName = '';
            }

            const resolvedDimensions = resolveDimensions(
                metadata,
                chartModel,
                skipOriginalMetricInAutoMode,
                isOriginallyV2,
                seriesMetadata,
                columnConfig,
                false,
                skipOriginalMetric
            );

            if (resolvedDimensions === null) {
                return 'Unknown';
            }

            if (!_.isEmpty(resolvedDimensions)) {
                return (
                    _.values(resolvedDimensions).join(' | ') + (plotName ? ' | ' + plotName : '')
                );
            } else {
                return plotName || '';
            }
        }

        function getPlotColor(seriesName, plotColors) {
            if (!seriesName || !seriesName.length || seriesName.indexOf('_SF_PLOT_KEY') === 0) {
                //unknown sources get this color
                return '#0096d0';
            }

            const colorIdx = Math.abs(murmurHash(seriesName)) % plotColors.length;
            return plotColors[colorIdx];
        }

        function showSparkline(chartconfig) {
            if (chartconfig.secondaryVisualization) {
                return chartconfig.secondaryVisualization === 'SPARKLINE';
            } else {
                return chartconfig.showSparkline;
            }
        }

        function getScrollableParents(container) {
            const scrollParents = [];
            container.parents().each(function () {
                const parent = angular.element(this);

                const hasSpaceToScroll = this.scrollHeight !== this.offsetHeight;
                // Scrollable parents are also those for which scroll is disabled
                const disabledScroll = parent.data('disabledScroll');

                const originalOverflow = parent.css('overflow');
                const isScrollable =
                    originalOverflow !== 'visible' && originalOverflow !== 'hidden';
                if ((hasSpaceToScroll && isScrollable) || disabledScroll) {
                    scrollParents.push(parent);
                }
            });
            return scrollParents;
        }

        function disableParentScrolling(container) {
            const scrollParents = getScrollableParents(container);
            scrollParents.forEach((parent) => {
                const disabledScroll = parent.data('disabledScroll');
                // On scroll disabled, overflow is hidden. originalOverflow should remain as it is.
                if (!disabledScroll) {
                    parent.data('originalOverflow', parent.css('overflow'));
                    parent.data('disabledScroll', true);
                    parent.css('overflow', 'hidden');
                }
            });
            return scrollParents;
        }

        function enableParentScrolling(scrollParents) {
            scrollParents.forEach(function (scrollParent) {
                const originalOverflow = scrollParent.data('originalOverflow');
                scrollParent.data('disabledScroll', false);
                scrollParent.css('overflow', originalOverflow);
            });
        }

        function getYAxis(idx) {
            return {
                id: 'yAxis' + idx,
                min: null,
                max: null,
                label: '',
                plotlines: {
                    low: null,
                    high: null,
                },
            };
        }

        function addUnresolvedYAxes(uiModel) {
            if (!uiModel.chartconfig.yAxisConfigurations) {
                uiModel.chartconfig.yAxisConfigurations = [];
            }

            const axisIndexReferences = {};
            uiModel.allPlots.forEach((plot) => {
                if (!plot.transient && (plot.type === 'plot' || plot.type === 'ratio')) {
                    axisIndexReferences[plot.yAxisIndex || 0] = true;
                }
            });

            if (axisIndexReferences[1]) {
                if (uiModel.chartconfig.yAxisConfigurations.length === 0) {
                    uiModel.chartconfig.yAxisConfigurations = [getYAxis(0), getYAxis(1)];
                } else if (uiModel.chartconfig.yAxisConfigurations.length === 1) {
                    uiModel.chartconfig.yAxisConfigurations.push(getYAxis(1));
                }
            } else if (
                axisIndexReferences[0] &&
                uiModel.chartconfig.yAxisConfigurations.length === 0
            ) {
                uiModel.chartconfig.yAxisConfigurations = [getYAxis(0)];
            }
        }

        return {
            getResolution,
            getSeriesMetadata,
            getSeriesMetadataFromPlot,
            resolveSeriesName,
            getFetchDuration,
            getEndDuration,
            getAliasedName,
            getJobRangeParameters,
            timeRangeDifferent,
            getMaxDecimalPlaces,
            isAbsoluteTime,
            getLegendKeys,
            isTimeSliceMode,
            mergeJobKeysAndColumnConfiguration,
            calculateRenderScore,
            calculateRenderScoreV2,
            getRenderThrottleStats,
            isRenderThrottledChartMode,
            getThresholdInfo,
            getThresholdVisibility,
            getThresholdType,
            getEventQueryRange,
            getSyntheticPlot,
            getDefaultVariables,
            setDefaultVariables,
            updateVariables,
            applyPointDensity,
            updateSources,
            applyUrlStateToModel,
            updateGlobalTimeRange,
            createConformingModelFromV2,
            getJobRangeParametersFromConfig,
            getFetchDurationFromConfig,
            getEndDurationFromConfig,
            isAbsoluteTimeFromConfig,
            getHighestCardinalityDimension,
            FIRE_TYPE,
            CLEAR_TYPE,
            getDisallowedKeys,
            getLabelInformation,
            processKnownLabels,
            getPlotObject,
            getPlotColor,
            jobMessagesReceived,
            showSparkline,
            resolveDimensions,
            getUiConfig,
            getScrollableParents,
            disableParentScrolling,
            enableParentScrolling,
            getSampleRate,
            jobMessagesReceivedV1,
            jobMessagesReceivedV2,
            addUnresolvedYAxes,
            getYAxis,
            getRollupMessage,
        };
    },
];
