// possible values of storageTier field for metric suggestions: 'ARCHIVED', 'PARTIALLY_ARCHIVED', 'REAL_TIME'
const ARCHIVED_STORAGE_TIERS = ['ARCHIVED', 'PARTIALLY_ARCHIVED'];

angular.module('chartbuilderUtil').service('plotSignalSuggestionService', [
    '$http',
    '$q',
    'API_URL',
    'chartbuilderUtil',
    'programTextUtils',
    'currentUser',
    function ($http, $q, API_URL, chartbuilderUtil, programTextUtils, currentUser) {
        const SUGGEST_ENDPOINT = `${API_URL}/v2/suggest/entities`;

        function getSyntheticResults(q, transient, allPlots, plot, chartMode) {
            const isTransient = !!transient;
            const allRequests = [];
            if (
                plot.type === 'ratio' &&
                !programTextUtils.isExpressionInvalid(q, allPlots, plot.uniqueKey)
            ) {
                allRequests.push($q.when([{ type: 'expression', value: q }]));
            }
            const isPlotType = plot.type === 'plot';
            const isEventType = plot.type === 'event';
            const isEventChart = chartMode === 'event';
            if ((isPlotType || isTransient) && !isEventChart) {
                allRequests.push(chartbuilderUtil.getGlobResults(q));
            }
            if (isEventType || isEventChart || isTransient) {
                allRequests.push(chartbuilderUtil.getEventGlobResults(q));
            }
            return $q.all(allRequests).then(function (resps) {
                let results = [];
                resps.forEach(function (r) {
                    results = results.concat(r);
                });
                return results;
            });
        }

        function getSuggestValues(q) {
            return currentUser.orgId().then(() => {
                return $http.get(SUGGEST_ENDPOINT, { params: { partialInput: q, limit: 100 } });
            });
        }

        async function getSignalSuggestions(q, transient, allPlots, plot, chartMode) {
            const syntheticResultsPromise = getSyntheticResults(
                q,
                transient,
                allPlots,
                plot,
                chartMode
            );
            const suggestDataPromise = getSuggestValues(q);

            const [syntheticResults, suggestData] = await Promise.all([
                syntheticResultsPromise,
                suggestDataPromise,
            ]);

            let rs = syntheticResults;

            const { detector: detectors, eventType: events, metric: metrics } = suggestData.data;

            if (plot.seriesData.regExStyle) {
                rs = rs.sort(function (a, b) {
                    return b.regExStyle === 'plain' ? -1 : 1;
                });
            }

            if (chartMode !== 'event' && chartMode !== 'eventOverlay') {
                metrics.forEach((metricItem) => {
                    const { name, type, storageTier } = metricItem;
                    const isArchived = ARCHIVED_STORAGE_TIERS.includes(storageTier?.toUpperCase());

                    rs.push({
                        type: 'metric',
                        value: name,
                        metricType: type,
                        isArchived,
                    });
                });
            }

            events.forEach((eventItem) => {
                rs.push({ type: 'eventTimeSeries', value: eventItem.eventType });
            });

            detectors.forEach((detectorItem) => {
                rs.push({ type: 'detectorEvents', value: detectorItem.name });
            });

            return rs;
        }

        return {
            getSignalSuggestions,
        };
    },
]);
